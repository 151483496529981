import { KatapultElement, html } from '../../mixins/katapult-element';

import { KatapultWizardTemplate } from './katapult-model-space-wizard-template';
import './katapult-model-space-guided-setup-wizard';
import './katapult-catalog-importer';
import './wizard-page';
import '../katapult-elements/katapult-button.js';

import { DefaultStyle } from './katapult-model-editor-style';

class KatapultModelEditorMapsGettingStartedWizard extends KatapultWizardTemplate {
  static get template() {
    return html`
      ${DefaultStyle}
      <style include="paper-dialog-style paper-table-style"></style>

      <katapult-catalog-importer id="catalogImporter"></katapult-catalog-importer>

      <wizard-page name="welcome">
        <div class="title">Welcome to [[config.firebaseData.name]] Maps!</div>
        <div class="prompt">Before you get started, we have a few final questions</div>
        <katapult-button class="wizardButton" color="var(--secondary-color)" on-click="beginGuidedSetup">Continue</katapult-button>
      </wizard-page>

      <wizard-page name="guidedSetup">
        <katapult-model-space-guided-setup-wizard
          id="guidedSetupWizard"
          compact
          model-space-config="{{modelSpaceConfig}}"
          user-modules="[[userModules]]"
          on-go-back="goToPreviousStep"
          on-finished="updateModelSpace"
        >
        </katapult-model-space-guided-setup-wizard>
      </wizard-page>

      <wizard-page name="updating">
        <div class="prompt">Please wait while we finish setting up</div>
        <template is="dom-if" if="[[equal(userGroup, 'katapult')]]">
          <img
            style="border-radius: 999px; height: 200px;"
            src="https://storage.googleapis.com/katapult-pro-shared-files/photos/wizard.gif"
          />
        </template>
        <template is="dom-if" if="[[!equal(userGroup, 'katapult')]]">
          <paper-spinner-lite active></paper-spinner-lite>
        </template>
      </wizard-page>

      <wizard-page name="finished">
        <iron-icon
          style="color: var(--paper-green-500); height: 128px; width: 128px;"
          style="margin-left: 16px;"
          icon="check-circle"
        ></iron-icon>
        <div
          style="background: white; color: var(--paper-grey-600); font-size: 14pt; padding: 16px; border-radius: 48px; border: 1px solid rgba(0,0,0,0.06);"
        >
          You're all set!
        </div>
      </wizard-page>
    `;
  }

  static get properties() {
    return {
      modelSpaceConfig: {
        type: Object,
        value: {}
      },
      steps: {
        type: Array,
        value: ['welcome', 'guidedSetup', 'updating', 'finished']
      }
    };
  }

  static get observers() {
    return [];
  }

  ready() {
    super.ready();
  }

  beginGuidedSetup() {
    this.goToStep('guidedSetup');
    this.modelSpaceConfig = {};
    this.$.guidedSetupWizard.begin();
  }

  async updateModelSpace() {
    if (this.userGroup) {
      this.goToStep('updating');
      let update = {};
      // Add make ready catalog.
      if (this.modelSpaceConfig.makeReadyRules) {
        await this.$.catalogImporter.importCatalog({
          catalog: this.modelSpaceConfig.makeReadyRules,
          modelSpace: this.userGroup,
          update
        });
      }
      // Write new model.
      await FirebaseWorker.ref()
        .update(update)
        .catch((err) => (error = err));
      // Setup pole loading.
      if (this.modelSpaceConfig.parsedClientFile && this.modelSpaceConfig.clientFileImporter) {
        await this.modelSpaceConfig.clientFileImporter
          .writeImport(this.userGroup, this.modelSpaceConfig.parsedClientFile)
          .catch((err) => (error = err));
      }
      // Remove prompt for catalog import (I'm choosing to set this to false so that we can know that it existed and was acted upon - KMG).
      FirebaseWorker.ref(`photoheight/company_space/${this.userGroup}/prompt_for_catalog_import`).set(false);
      this.goToStep('finished');
      setTimeout(() => {
        this.dispatchEvent(new CustomEvent('finished'));
      }, 2000);
    }
  }
}

customElements.define('katapult-model-editor-maps-getting-started-wizard', KatapultModelEditorMapsGettingStartedWizard);
