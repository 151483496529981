import '@polymer/paper-tooltip/paper-tooltip.js';
import '@polymer/iron-icons/notification-icons.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/iron-icon/iron-icon.js';
import '@polymer/paper-progress/paper-progress.js';
import moment from 'moment';
import { html } from '@polymer/polymer/lib/utils/html-tag.js';
import { PolymerElement } from '@polymer/polymer/polymer-element.js';

import '../k-logic-editor/k-logic-editor.js';
import '../katapult-drop-down/katapult-drop-down.js';
import '../katapult-elements/katapult-button.js';
import { KatapultDialog } from '../katapult-elements/katapult-dialog.js';

import { MLD } from '../../modules/MasterLocationDirectory.js';
import { DEFAULT_SCHEMAS } from '../../modules/KLogicSchemas.js';
import { SquashNulls } from '../../modules/SquashNulls.js';
import { ToArray } from '../../modules/ToArray.js';
import { GetCheckinDialogHTML } from './GetCheckInDialogHTML.js';
import { ToTitleCase } from '../../modules/ToTitleCase.js';
import { addFlagListener } from '../../modules/FeatureFlags.js';

/* global firebase, k */
class MasterLocationDirectoryManager extends PolymerElement {
  static get template() {
    return html`
      <style>
        .addedAttributeContainer {
          margin-left: 10px;
        }
        .addedAttribute {
          background-color: var(--primary-color-light);
          color: var(--primary-color-text-color);
          border-radius: 20px;
          padding-left: 10px;
          display: inline-flex;
          align-items: center;
          font-size: 14px;
        }
        .addedAttribute katapult-button {
          height: 35px;
          color: var(--primary-color-text-color);
        }
        .promptDialog {
          width: 400px;
        }
        paper-tabs {
          --paper-tabs-selection-bar-color: var(--primary-color);
        }
        paper-tab {
          --paper-tab-ink: var(--primary-color);
        }
      </style>

      <firebase-document
        path="[[jobMasterLocationDirectoryDataPath]]"
        data="{{jobMasterLocationDirectoryData}}"
        disabled="[[!signedIn]]"
      ></firebase-document>

      <!-- K Logic Editor Dialog -->
      <katapult-dialog id="kLogicEditorDialog" fit-into="[[katapultMaps.$.mainHorizContainer]]" minWidth="500" draggable modal>
        <div slot="title" secondary-color style="text-transform:uppercase;">Check In Filter Logic</div>
        <div style="display: flex; flex-direction: column; gap: 16px;">
          <div style="display: flex; align-items: center; gap: 8px;">
            <material-icon icon="filter_alt"></material-icon>
            <div style="font-size: 16px; font-weight: 500;">Check In Condition</div>
          </div>
          <div>Nodes in this directory will only be checked in if the following condition is true</div>
          <k-logic-editor schema="[[_filterLogicSchema]]" block="{{selectedDirectory.checkInFilter}}" expand-editor></k-logic-editor>
          <div slot="buttons">
            <katapult-button dialog-dismiss on-click="cancelFilterChanges">Cancel</katapult-button>
            <katapult-button color="var(--secondary-color)" dialog-confirm on-click="saveFilter">Save</katapult-button>
          </div>
        </div>
      </katapult-dialog>

      <!-- Main Dialog -->
      <katapult-dialog id="masterLocationDirectoryDialog" maxWidth="650" title="Master Location Directories">
        <div>
          <p>
            Master Location Directories are layers that you can enable from the Location Directories layers menu. You can create up to four
            directories and choose which jobs are checked into each directory. When you turn on a directory layer, location markers for
            poles from every job checked into the directory will appear on the map. For each directory, you can set a location color and you
            can specify up to three attributes to check in with each pole. These attributes will then become searchable from the search bar.
          </p>
          <paper-tabs selected="{{masterLocationDirectoryDialogSelectedTab}}">
            <paper-tab>Job Settings</paper-tab>
            <paper-tab>Directory Settings</paper-tab>
          </paper-tabs>
          <iron-pages selected="{{masterLocationDirectoryDialogSelectedTab}}">
            <div>
              <template is="dom-if" if="[[!directories.length]]">
                <p style="text-align:center; margin-top:35px;">There are no directories set up yet. Add one in Directory Settings.</p>
              </template>
              <template is="dom-if" if="[[directories.length]]">
                <p>
                  Below you can see the last time the current job was checked into each directory. You can check the job in again to update
                  the pole positions (and attributes if applicable), or remove the job's poles from a directory altogether.
                </p>
              </template>
              <div style="margin: 15px">
                <template is="dom-repeat" items="[[directories]]" as="directory">
                  <div style="display:flex; flex-direction:row; align-items:center; justify-content:space-between;">
                    <div>
                      <p>
                        <span style="font-weight:bold;">[[directory.name]]</span>
                        <br />
                        Last Checked In: {{getDirectoryCheckInDate(directory.$key, jobMasterLocationDirectoryData)}}
                      </p>
                    </div>
                    <div></div>
                    <div style="display:flex; flex-direction: row;">
                      <paper-spinner id="spinner[[directory.$key]]" style="top:6px;"></paper-spinner>
                      <katapult-button iconOnly noBorder id="checkInJobButton[[directory.$key]]" icon="sync" on-click="checkInJob">
                        <paper-tooltip for="checkInJobButton[[directory.$key]]">Check in job</paper-tooltip>
                      </katapult-button>
                      <katapult-button
                        iconOnly
                        noBorder
                        id="removeJobFromDirectoryButton[[directory.$key]]"
                        icon="delete"
                        on-click="removeJobFromDirectory"
                      >
                        <paper-tooltip for="removeJobFromDirectoryButton[[directory.$key]]">Remove job</paper-tooltip>
                      </katapult-button>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div>
              <div style="margin: 15px">
                <template is="dom-if" if="[[!directories.length]]">
                  <p style="text-align:center; margin-top:35px;">There are no directories yet...</p>
                </template>
                <template is="dom-repeat" items="[[directories]]" as="directory">
                  <div style="display:flex; flex-direction:row; align-items:center;">
                    <katapult-button
                      iconOnly
                      noBorder
                      id="deleteDirectoryButton[[directory.$key]]"
                      icon="delete"
                      on-click="promptToDeleteDirectory"
                    >
                      <paper-tooltip for="deleteDirectoryButton[[directory.$key]]">Delete directory...</paper-tooltip>
                    </katapult-button>
                    <span style="font-weight:bold">[[directory.name]]</span>
                  </div>
                  <div style="margin-left:41px; margin-bottom:18px;">
                    <div style="display:flex; flex-direction:row; align-items:center;">
                      <span>Attributes:</span>
                      <template is="dom-repeat" items="[[getDirectoryAttributesList(directory)]]" as="attribute">
                        <div class="addedAttributeContainer">
                          <div class="addedAttribute">
                            [[ToTitleCase(attribute.$key)]]
                            <katapult-button
                              iconOnly
                              noBorder
                              textcolor="white"
                              icon="close"
                              on-click="removeAttributeFromDirectory"
                              style="padding: 0 8px;"
                            ></katapult-button>
                          </div>
                        </div>
                      </template>
                      <katapult-button
                        iconOnly
                        noBorder
                        icon="add_circle"
                        on-click="promptToAddAttributeToDirectory"
                        disabled="[[addNewAttributesForDirectoryDisabled(directory)]]"
                      ></katapult-button>
                    </div>
                    <div style="display:flex; flex-direction:row; align-items:center; margin-top:5px;">
                      <span>Location Color:</span>
                      <div
                        style="background-color:[[directory.default_location_color]]; border:2px solid black; border-radius:3px; width:20px; height:20px; margin-left:10px;"
                      ></div>
                    </div>
                    <div style="display:flex; flex-direction:row; align-items:center; margin-top:5px;">
                      <span>Check In Filter:</span>
                      <katapult-button
                        class="klogicButton"
                        noBorder
                        iconOnly
                        on-click="openKLogicEditor"
                        icon="functions"
                      ></katapult-button>
                    </div>
                  </div>
                </template>
              </div>
              <div style="margin-top:38px; text-align:center;">
                <katapult-button
                  style="width: 200px;"
                  color="var(--secondary-color)"
                  disabled="[[addNewDirectoriesDisabled(directories, allow25LocationDirectoriesFlag)]]"
                  on-click="promptForDirectoryName"
                  >Add New Directory</katapult-button
                >
              </div>
            </div>
          </iron-pages>
        </div>
        <div slot="buttons">
          <katapult-button dialog-dismiss color="var(--primary-color)">Close</katapult-button>
        </div>
      </katapult-dialog>

      <!-- Add Attribute Dialog -->
      <katapult-dialog id="addAttributeDialog" class="promptDialog" title="Add Directory Attribute" width="400">
        <p>
          Choose an attribute to add to this directory. You may add up to three attributes per directory. Directory attributes will only be
          checked into the directory with new locations, not existing.
        </p>
        <katapult-drop-down
          items="[[otherAttributes]]"
          label-path="$key"
          value-path="$key"
          value="{{selectedAttribute}}"
          label="Choose an Attribute"
        ></katapult-drop-down>
        <katapult-button slot="buttons" dialog-dismiss>Cancel</katapult-button>
        <katapult-button slot="buttons" color="var(--secondary-color)" on-click="confirmAddAttributeToDirectory">Add</katapult-button>
      </katapult-dialog>

      <!-- Delete Dialog -->
      <katapult-dialog id="directoryDeleteDialog" class="promptDialog" title="Delete Directory" color="red" width="400">
        <p>
          Are you sure you want to delete this directory? This will remove all locations checked into the directory. This process may take a
          while if there are many locations checked into the direction.
        </p>
        <paper-progress slot="buttons" id="removeDirectoryProgress" hidden style="margin-right:12px;"></paper-progress>
        <katapult-button slot="buttons" dialog-dismiss>Cancel</katapult-button>
        <katapult-button slot="buttons" color="var(--paper-red-500)" on-click="confirmDeleteDirectory">Yes</katapult-button>
      </katapult-dialog>

      <!-- Name Input Dialog -->
      <katapult-dialog
        id="directoryNameDialog"
        class="promptDialog"
        title="Add New Directory"
        on-iron-overlay-closed="resetDirectoryNameDialog"
        width="400"
      >
        <p>
          Choose a name for the directory and color for locations in the directory. The location color cannot be edited once the directory
          is created.
        </p>
        <paper-input id="newDirectoryNameInput" value="{{newDirectoryName}}" label="New Directory Name"></paper-input>
        <label>Location Color:</label><input id="newDirectoryColorInput" style="margin:8px" type="color" value="#ffffff" />
        <katapult-button slot="buttons" dialog-dismiss>Cancel</katapult-button>
        <katapult-button slot="buttons" color="var(--secondary-color)" on-click="confirmAddNewDirectory">Create</katapult-button>
      </katapult-dialog>
    `;
  }

  static get properties() {
    return {
      directories: {
        type: Array,
        value: () => []
      },
      isOpen: {
        type: Boolean,
        value: false
      },
      jobMasterLocationDirectoryDataPath: {
        type: String,
        computed: 'computeJobMasterLocationDirectoryDataPath(jobId)'
      },
      masterLocationDirectoryDialogSelectedTab: {
        type: Number,
        value: 0
      }
    };
  }

  static get observers() {
    return ['toggleDirectoryListener(userGroup, isOpen)'];
  }

  static get is() {
    return 'master-location-directory-manager';
  }

  constructor() {
    super();
    this.ToTitleCase = ToTitleCase;
    this._filterLogicSchema = { node: DEFAULT_SCHEMAS.NODE, attribute: 'any' };
  }

  ready() {
    super.ready();

    addFlagListener('allow_25_location_directories', (enabled) => (this.allow25LocationDirectoriesFlag = enabled));
  }

  addNewDirectoriesDisabled(directories, allow25LocationDirectoriesFlag) {
    const defaultDirectoryLimit = 4;
    const directoryLimit25 = 25;
    const directoryLimit = allow25LocationDirectoriesFlag == true ? directoryLimit25 : defaultDirectoryLimit;
    const directoryCount = (directories || []).length;
    const isAtLimit = directoryCount >= directoryLimit;
    return isAtLimit;
  }

  addNewAttributesForDirectoryDisabled(directory) {
    return this.getDirectoryAttributesList(directory).length >= 3;
  }

  getDirectoryAttributesList(directory) {
    return ToArray(SquashNulls(directory, 'location_attributes') || {});
  }

  promptToAddAttributeToDirectory(e) {
    this.directoryToUpdate = SquashNulls(e, 'model', 'directory', '$key') || null;
    this.$.addAttributeDialog.open();
  }

  confirmAddAttributeToDirectory() {
    let guiElement = SquashNulls(this.otherAttributes, this.selectedAttribute, 'gui_element');
    if (this.userGroup && this.selectedAttribute && guiElement && this.directoryToUpdate) {
      MLD.addDirectoryLocationAttribute(this.userGroup, this.selectedAttribute, guiElement, this.directoryToUpdate);
      this.directoryToUpdate = null;
      this.$.addAttributeDialog.close();
    }
  }

  removeAttributeFromDirectory(e) {
    let attributeId = SquashNulls(e, 'model', 'attribute', '$key');
    let directoryId = SquashNulls(e, 'model', 'parentModel', 'directory', '$key');
    if (this.userGroup && attributeId) {
      MLD.removeDirectoryLocationAttribute(this.userGroup, attributeId, directoryId);
    }
  }

  getDirectoryCheckInDate(directoryId) {
    if (
      this.jobMasterLocationDirectoryData &&
      this.jobMasterLocationDirectoryData[directoryId] &&
      this.jobMasterLocationDirectoryData[directoryId].last_check_in
    ) {
      return this.formatMomentDate(this.jobMasterLocationDirectoryData[directoryId].last_check_in, 'MM/DD/YYYY h:mma');
    }
    return 'Never';
  }

  formatMomentDate(d, format) {
    if (d) {
      return moment(d).format(format);
    }
    return '';
  }

  computeJobMasterLocationDirectoryDataPath(jobId) {
    if (jobId) {
      return `photoheight/jobs/${jobId}/metadata/master_location_directory`;
    }
    return '';
  }

  async toggleDirectoryListener() {
    // If we have a user group and the dialog is open, then start the listener
    if (this.userGroup && this.isOpen) {
      // Set up a listener for the directories and get an unsubscribe function
      this.listenerOff = firebase
        .firestore()
        .collection(`/companies/${this.userGroup}/directories`)
        .onSnapshot((querySnapshot) => {
          let directories = [];
          querySnapshot.forEach((doc) => {
            directories.push(Object.assign({ $key: doc.id }, doc.data()));
          });
          // Order by date created
          directories.sort((a, b) => {
            // TODO (2024-03-01): There are some MLDs that don't have a date_created field. This if statement is a temporary fix to provide a fallback for sorting instead of throwing an error. See similar TODO in katapult-maps-desktop.js
            if (!a.date_created || !b.date_created) {
              if (!a.date_created && !b.date_created) return a - b;
              else if (!a.date_created) return b - a;
              else return a - b;
            }
            return a.date_created.seconds - b.date_created.seconds;
          });
          this.directories = directories;
        });
    } else {
      // Otherwise, unsubscribe from the listener if we have one
      if (this.listenerOff) this.listenerOff();
      this.listenerOff = null;
    }
  }

  async checkInJob(e) {
    let jobId = SquashNulls(e, 'detail', 'jobId') || this.jobId;
    let directoryId = SquashNulls(e, 'model', 'directory', '$key');
    if (this.userGroup && jobId && directoryId) {
      let spinner = this.shadowRoot.querySelector(`#spinner${directoryId}`);
      if (spinner) spinner.active = true;
      // Get the nodes
      let nodes = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/nodes`)
        .once('value')
        .then((s) => s.val());
      // Check them into the job
      let checkInWarnings = { title: 'Check In Summary', jobSummaries: [] };
      await MLD.checkInJob(jobId, nodes, this.userGroup, directoryId, { showWarnings: checkInWarnings });
      if (this.jobMasterLocationDirectoryDataPath) {
        // Set the last check in time to one minute from now so that it will be ahead the job last updated timestamp
        const oneMinuteFromNow = Date.now() + 60000;
        await FirebaseWorker.ref(`${this.jobMasterLocationDirectoryDataPath}/${directoryId}/last_check_in`).set(oneMinuteFromNow);
      }
      if (spinner) spinner.active = false;

      // show the warnings dialog if we have some
      if (checkInWarnings.jobSummaries?.length > 0) {
        let katapultDialogHTML = GetCheckinDialogHTML(checkInWarnings);
        KatapultDialog.open({ template: () => katapultDialogHTML, dialog: { closeButton: true, draggable: true } });
      }
    }
  }

  removeJobFromDirectory(e) {
    let jobId = SquashNulls(e, 'detail', 'jobId') || this.jobId;
    let directoryId = SquashNulls(e, 'model', 'directory', '$key');
    if (this.userGroup && jobId && directoryId) {
      let spinner = this.shadowRoot.querySelector(`#spinner${directoryId}`);
      if (spinner) spinner.active = true;
      if (!this.jobMasterLocationDirectoryData) this.jobMasterLocationDirectoryData = {};
      return MLD.removeJobFromDirectory(jobId, this.userGroup, directoryId).then(async () => {
        if (this.jobMasterLocationDirectoryDataPath) {
          await FirebaseWorker.ref(`${this.jobMasterLocationDirectoryDataPath}/${directoryId}`).set(null);
          if (spinner) spinner.active = false;
        }
      });
    }
  }

  promptForDirectoryName() {
    this.$.directoryNameDialog.open();
  }

  confirmAddNewDirectory() {
    if (this.userGroup) {
      let defaultLocationColor = this.$.newDirectoryColorInput.value || '#ffffff';
      MLD.createDirectory(this.userGroup, this.newDirectoryName, { defaultLocationColor })
        .then(() => {
          this.$.directoryNameDialog.close();
        })
        .catch((err) => {
          console.log('err', err);
          if (err) {
            this.$.newDirectoryNameInput.errorMessage = err;
            this.$.newDirectoryNameInput.invalid = true;
          }
        });
    }
  }

  promptToDeleteDirectory(e) {
    this.directoryToDelete = SquashNulls(e, 'model', 'directory') || null;
    this.$.directoryDeleteDialog.open();
  }

  async confirmDeleteDirectory() {
    if ((this.userGroup, this.directoryToDelete)) {
      // Set the progress bar max to the number of jobs in the directory
      this.$.removeDirectoryProgress.value = 0;
      this.$.removeDirectoryProgress.max = this.directoryToDelete.job_count || 0;
      this.$.removeDirectoryProgress.hidden = false;
      let promiseList = [];
      // Loop until we don't have any more locations in the directory
      let nextLocationQuery = null;
      do {
        // Get the next location we should check for a new job to remove
        nextLocationQuery = await firebase
          .firestore()
          .collection(`companies/${this.userGroup}/directories/${this.directoryToDelete.$key}/locations`)
          .limit(1)
          .get();
        if (nextLocationQuery.docs.length > 0) {
          let firstLocationData = nextLocationQuery.docs[0].data();
          // Get the jobs for the location
          let locationJobs = SquashNulls(firstLocationData, 'd', 'j') || [];
          // Loop through each job and remove them
          for (let i = 0; i < locationJobs.length; i++) {
            let jobId = locationJobs[i];
            // Add the job removal promise to the promise list
            promiseList.push(MLD.removeJobFromDirectory(jobId, this.userGroup, this.directoryToDelete.$key));
            // Clean up the directory metadata on the job
            promiseList.push(
              FirebaseWorker.ref(`${this.computeJobMasterLocationDirectoryDataPath(jobId)}/${this.directoryToDelete.$key}`).set(null)
            );
            // Update the progress
            this.$.removeDirectoryProgress.value++;
          }
          await Promise.all(promiseList);
          promiseList = [];
        }
      } while (nextLocationQuery.docs.length > 0);
      // Remove the directory
      await MLD.removeDirectory(this.userGroup, this.directoryToDelete.$key);
      this.$.directoryDeleteDialog.close();
      this.directoryToDelete = null;
      this.$.removeDirectoryProgress.hidden = true;
      this.$.removeDirectoryProgress.value = 0;
    }
  }

  resetDirectoryNameDialog() {
    this.newDirectoryName = '';
    this.$.newDirectoryNameInput.errorMessage = '';
    this.$.newDirectoryNameInput.invalid = false;
    this.$.newDirectoryColorInput.value = '#ffffff';
  }

  open() {
    if (this.jobId) {
      this.$.masterLocationDirectoryDialog.open();
      this.isOpen = true;
    }
  }

  close() {
    this.$.masterLocationDirectoryDialog.close();
    this.isOpen = false;
  }

  openKLogicEditor(e) {
    // only open the dialog if we have a directory
    if (e.model.directory) {
      this.$.kLogicEditorDialog.open();
      this.selectedDirectory = e.model.directory;
      this.startingCheckInFilter = JSON.parse(JSON.stringify(e.model.directory?.checkInFilter));
    }
  }

  async saveFilter() {
    let directoryId = this.selectedDirectory.$key;
    let newFilter = this.selectedDirectory.checkInFilter;
    // save the filter to firestore under the directory id of the directory being edited.
    await firebase.firestore().collection(`companies/${this.userGroup}/directories`).doc(directoryId).update({ checkInFilter: newFilter });

    // clear the selected directory
    this.selectedDirectory = null;
    this.startingCheckInFilter = null;
  }

  cancelFilterChanges() {
    this.selectedDirectory.checkInFilter = this.startingCheckInFilter;
    this.selectedDirectory = null;
  }
}

window.customElements.define(MasterLocationDirectoryManager.is, MasterLocationDirectoryManager);
