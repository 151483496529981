import { Path } from './Path.js';
import { GeoFire } from 'geofire';
import { Convert } from './Convert.js';
import { GeofireTools } from './GeofireTools.js';

/**
 * Takes in a master location directory id (or ids) and returns all necessary overlapping notes
 * If an update object is passed in, add overlapping_note attribute to the update object
 *
 * @param {Array<string>} locationDirectoryIds - an array of master location directory ids to search
 * @param {Object} options
 * @param {Object} [options.update] - the update object to add the overlapping_note attribute to
 * @param {string} options.nodeId - the node id to add the overlapping_note attribute to
 * @param {Object} options.nodes - the nodes object
 * @param {string} options.jobId - the current job id
 * @param {boolean} options.hasAccessToAllApps - whether or not the user has access to all app metadata
 * @param { {lat, lon} } options.nodeLocation - the node latitutde and longitude
 * @param {string} options.userGroup - the user group
 * @returns {Promise<Object>}
 *
 */
export async function AddOverlappingNotes(locationDirectoryIds, options) {
  let update = options?.update;
  const nodeId = options?.nodeId;
  let nodes = options?.nodes;
  const currJobId = options?.jobId;
  const hasAccessToAllApps = options?.hasAccessToAllApps;
  let overlappingNotes = {};
  let nodeLat = options?.nodeLocation?.lat;
  let nodeLng = options?.nodeLocation?.lng;
  let userGroup = options?.userGroup;

  let companyId = null;

  if (locationDirectoryIds && nodeLat && nodeLng && userGroup) {
    // check to see if there are any nodes in the master location directory that are within 36ft (number is feet -> km)
    let lookup = {};

    // loop through all of the master location directories and get all of the nodes that match in it
    for (let locationDirectoryId of locationDirectoryIds) {
      let closePolesGeoData;

      // Get the shared directories for the user's company
      const sharedDirectoryData = (
        await firebase.firestore().doc(`companies/${userGroup}/shared_directories/${locationDirectoryId}`).get()
      ).data();

      // Check if the location directory is a shared directory and correctly set the companyId
      companyId = sharedDirectoryData?.owner_company || userGroup;

      // get the radius for the master location directory
      let masterLocationDirectoryInfo = (
        await firebase.firestore().doc(`companies/${companyId}/directories/${locationDirectoryId}`).get()
      ).data();
      let masterLocationDirectoryRadius = masterLocationDirectoryInfo?.radius;
      // if there is a radius, then use that to see if nodes overlap
      if (masterLocationDirectoryRadius) {
        closePolesGeoData = await new GeoFire(
          firebase.firestore().collection(`companies/${companyId}/directories/${locationDirectoryId}/locations`)
        ).once({ radius: Convert(masterLocationDirectoryRadius, 'ft', 'km'), center: [nodeLat, nodeLng] });
        for (const closePoleGeoData of Object.values(closePolesGeoData)) {
          let jobIds = closePoleGeoData?.d?.j || [];
          for (let jobId of jobIds) {
            // get the app number from the job name
            let appName = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/name`)
              .once('value')
              .then((s) => s.val());
            let app_number = appName.replace('APP_', '');

            // add the job and app number to the lookup
            if (app_number) lookup[jobId] = { app_number };
          }
        }
      }
      // if there is no radius, fall back to comparing the geohash for the node and the locations in the directory
      else {
        let nodeGeohash = GeofireTools._encodeGeohash([nodeLat, nodeLng]);
        let matchingHashes = (
          await firebase
            .firestore()
            .collection(`companies/${companyId}/directories/${locationDirectoryId}/locations`)
            .where('g', '==', nodeGeohash)
            .get()
        ).docs;

        if (matchingHashes.length > 0) {
          for (let matchingHash of matchingHashes) {
            let closePoleGeoData = matchingHash.data();
            let jobIds = closePoleGeoData?.d?.j || [];
            for (let jobId of jobIds) {
              // get the app number from the job name
              let appName = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/name`)
                .once('value')
                .then((s) => s.val());
              let app_number = appName.replace('APP_', '');

              // add the job and app number to the lookup
              if (app_number) lookup[jobId] = { app_number };
            }
          }
        }
      }
    }

    if (Object.keys(lookup).length != 0) {
      let overlappingApps = Object.keys(lookup).filter((x) => x != currJobId);
      let apps = [];
      if (overlappingApps.length) {
        for (let i = 0; i < overlappingApps.length; i++) {
          let jobId = overlappingApps[i];
          // only want to display a note once for each app, handles duplicates of the job
          if (!apps.includes(lookup[jobId].app_number)) {
            // Add overlapping_note attributes.
            let key = FirebaseWorker.ref().push().key;
            let appStatus = hasAccessToAllApps
              ? await FirebaseWorker.ref(`photoheight/jobs/${jobId}/metadata/app_status`)
                  .once('value')
                  .then((s) => s.val() || 'App Status Not Set')
              : null;
            let note = appStatus
              ? `Overlaps with APP_${lookup[jobId].app_number} (${appStatus})`
              : `Overlaps with APP_${lookup[jobId].app_number}`;
            if (update) {
              update[`nodes/${nodeId}/attributes/overlapping_note/${key}`] = note;
              Path.set(nodes[nodeId], 'attributes.overlapping_note.' + key, note);
            }
            overlappingNotes[key] = note;
            apps.push(lookup[jobId].app_number);
          }
        }
      }
    }
  }
  return overlappingNotes;
}
