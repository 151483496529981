import { KatapultElement } from '../../../mixins/katapult-element.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-dialog/paper-dialog.js';
import '@polymer/paper-dialog-scrollable/paper-dialog-scrollable.js';
import '@polymer/paper-radio-group/paper-radio-group.js';
import '@polymer/paper-radio-button/paper-radio-button.js';
import '@polymer/paper-spinner/paper-spinner.js';
import '../../katapult-drop-down/katapult-drop-down.js';
import '../../model-loader/model-loader.js';
import '../../katapult-map-icons/katapult-map-icons.js';
import '../../k-logic-editor/k-logic-editor.js';
import '../../paper-table/paper-table.js';
import '../../style-modules/katapult-scrollbars.js';
import '../../style-modules/paper-dialog-style.js';
import '../../style-modules/paper-menu-button-style.js';
import '../../katapult-dialog-legacy/katapult-dialog-legacy.js';
import '../../katapult-color-picker/katapult-color-picker-button.js';
import { blobStream } from '../../../js/open-source/blob-stream.js';
import { SquashNulls } from '../../../modules/SquashNulls.js';
import { CamelCase } from '../../../modules/CamelCase.js';
import { GetMainPhoto } from '../../../modules/GetMainPhoto.js';
import { GetAttributeLookup } from '../../../modules/GetItemName.js';
import { DefaultComputedBindings } from '../../../modules/DefaultComputedBindings.js';
import { html } from '@polymer/polymer/lib/utils/html-tag.js';
import { dom } from '@polymer/polymer/lib/legacy/polymer.dom.js';
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js';
import { OpenPage } from '../../../modules/OpenPage.js';
import { NotifyResizable } from '../../../modules/NotifyResizable.js';
import { Round } from '../../../modules/Round.js';
import { GeofireTools } from '../../../modules/GeofireTools.js';
import { KatapultGeometry } from 'katapult-toolbox';
import { SVGtoPDF } from '../../../modules/SVGtoPDF.js';
import { StyleRuleToIcon } from '../../../modules/StyleRuleToIcon.js';
import { GeoStyleToIcon } from '../../../modules/GeoStyleToIcon.js';
import { KLogic } from '../../../modules/KLogic.js';
import { DEFAULT_SCHEMAS } from '../../../modules/KLogicSchemas.js';
import { calcMakeReadyNotes } from './print-generator.js';
import { addFlagListener } from '../../../modules/FeatureFlags.js';
import { GetKLogicStandardDataset } from '../../../modules/GetKLogicStandardDataset.js';
import { GetJobData } from '../../../modules/GetJobData.js';

/*global firebase, google, k, Polymer, GeofireTools*/
class PrintModeToolbar extends mixinBehaviors([DefaultComputedBindings], KatapultElement) {
  static get template() {
    return html`
      <style include="katapult-scrollbars paper-menu-button-style paper-dialog-style paper-tooltip-style">
        :host {
          position: relative;
          display: flex;
          flex-direction: row;
          background-color: white;
          @apply --shadow-elevation-4dp;
          transition: margin-right 0.3s;
          z-index: 3;
          width: 360px;
        }
        :host(:not([show-toolbar])) {
          margin-right: -360px;
        }
        #toolsContainer {
          display: flex;
          flex-direction: column;
          flex-shrink: 0;
          overflow-y: auto;
          width: 100%;
          height: 100%;
          transition: margin-right 0.3s;
          /*transform: translate(0);*/
        }

        #scrollContainer {
          position: relative;
          overflow: auto;
        }

        /* START KMG */
        #mainHeader {
          background-color: var(--secondary-color);
          color: white;
          padding: 16px;
          z-index: 1;
          transition: box-shadow 0.3s;
        }

        #mainHeader[raised=''] {
          @apply --shadow-elevation-2dp;
        }

        #mainHeader .header {
          font-size: 14pt;
          padding: 0;
        }

        #mainHeader .header iron-icon {
          margin-right: 8px;
        }

        #mainHeader katapult-drop-down {
          --katapult-drop-down-arrow-color: var(--secondary-color-text-color-faded);
          flex-grow: 1;
          --katapult-drop-down-input: {
            --paper-input-container-color: var(--secondary-color-text-color-faded);
            --paper-input-container-focus-color: var(--secondary-color-text-color);
          };
          --katapult-drop-down-input-color: var(--secondary-color-text-color);
          --katapult-drop-down-list: {
            color: var(--primary-text-color);
          };
        }

        .row,
        .header {
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          width: 100%;
        }

        .header {
          z-index: 1;
          transition: all 0.3s;
          padding: 8px 16px;
          text-transform: uppercase;
        }

        .toolSection {
          color: var(--primary-text-color-faded);
        }

        .toolSection .header {
          background-color: var(--paper-grey-200);
          color: var(--primary-text-color-faded);
          text-transform: uppercase;
          font-size: 11pt;
          transition: all 0.3s;
          padding: 8px 16px;
          z-index: 1;
        }

        .toolSection .header[active=''] {
          @apply --shadow-elevation-2dp;
        }

        .toolSection .header .title {
          flex-grow: 1;
        }

        .toolSection .header .collapseIcon {
          transition: transform 0.3s;
        }

        .toolSection .header[active=''] .collapseIcon {
          transform: rotate(-180deg);
        }

        .toolSection paper-table {
          padding: 8px 0;
        }

        .toolSection paper-table paper-cell.label {
          flex-basis: initial;
          flex-grow: initial;
          width: 132px;
        }

        paper-row[slot='sortable'] {
          background-color: white;
        }

        paper-row[slot='sortable'] paper-cell:first-of-type {
          flex-basis: initial;
          flex-grow: initial;
          width: 48px;
        }

        paper-checkbox {
          --paper-checkbox-checked-color: var(--secondary-color);
          --paper-checkbox-unchecked-color: var(--primary-text-color-faded);
          --paper-checkbox-label-color: var(--primary-text-color-faded);
        }

        paper-radio-button {
          --paper-radio-button-checked-color: var(--secondary-color);
          --paper-radio-button-unchecked-color: var(--primary-text-color-faded);
          --paper-radio-button-label-color: var(--primary-text-color-faded);
        }

        katapult-button:not([iconOnly]) {
          min-width: 75px;
        }
        /* END KMG */

        /*.toolSection {*/
        /*  padding:20px;*/
        /*  border-bottom:2px solid gray;*/
        /*  font-size:14px;*/
        /*}*/

        /*.toolSection .header {*/
        /*  margin-bottom:10px;*/
        /*}*/

        /*.toolSection .header .title {*/
        /*  text-transform: uppercase;*/
        /*  font-size:16px;*/
        /*  font-weight: 500;*/
        /*}*/

        .toolSection .row {
          display: flex;
          align-items: center;
        }

        .toolSection .row .indentedSection {
          margin-left: 10px;
        }

        .dialog {
          min-width: 400px;
        }

        #previewDialog {
          width: 800px;
        }
        #clearAnnotations {
          margin-right: 0;
        }
        #ruler {
          visibility: hidden;
          white-space: pre;
          position: absolute;
          z-index: -1;
          font-size: 12pt;
          font-family: Helvetica;
        }
        #customScaleInput {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        #scaleButtons {
          justify-content: center;
        }
        .overwriteCheckbox {
          --paper-checkbox-label-color: black;
          margin: 10px;
        }
        .legendItem {
          display: flex;
          align-items: center;
          margin: 5px 0;
          position: relative;
          --paper-input-container-disabled: {
            opacity: 0.8;
          };
        }
        .legendIconContainer {
          display: flex;
          margin-right: 16px;
          justify-content: center;
          align-items: center;
          position: relative;
          text-align: center;
          width: 24px;
          height: 24px;
        }
        .strikethrough {
          position: absolute;
          height: 2px;
          width: calc(100% - 80px);
          margin: 8px 0px 0 40px;
          top: 50%;
          background-color: var(--primary-text-color-faded);
        }
        .klogicButton {
          border-radius: 24px;
        }
      </style>

      <model-loader
        company-id="[[jobCreator]]"
        items='["attributes", "export_models/map_print_templates", "map_print_annotation_logic"]'
        other-attributes="{{otherAttributes}}"
        export-models-map-print-templates="{{companyMapPrintTemplates}}"
        map-print-annotation-logic="{{mapPrintAnnotationLogic}}"
      >
      </model-loader>

      <firebase-document
        id="jobMapPrintConfigs"
        path="photoheight/jobs/[[jobId]]/map_print_configs"
        data="{{jobMapPrintConfigs}}"
        disabled="[[disabled]]"
      ></firebase-document>
      <firebase-document
        id="currentMapPrintConfig"
        path="photoheight/jobs/[[jobId]]/map_print_configs/[[currentMapPrintConfigsKey]]"
        data="{{currentMapPrintConfig}}"
        disabled="[[disabled]]"
      ></firebase-document>
      <firebase-document
        id="currentMapPrintPages"
        path="photoheight/jobs/[[jobId]]/map_print_config_layers/[[currentMapPrintConfigsKey]]/pages"
        data="{{currentMapPrintPages}}"
        disabled="[[disabled]]"
      ></firebase-document>
      <firebase-document
        id="savedViews"
        path="photoheight/jobs/[[jobId]]/saved_views"
        data="{{savedViews}}"
        disabled="[[disabled]]"
      ></firebase-document>
      <firebase-document id="files" path="photoheight/company_space/[[jobCreator]]/models/files" data="{{files}}"></firebase-document>
      <katapult-firebase-worker
        id="companyMapStyles"
        path="photoheight/company_space/[[userGroup]]/models/map_styles"
        data="{{companyMapStyles}}"
        disabled="[[disabled]]"
        loading="{{companyMapStylesLoading}}"
      ></katapult-firebase-worker>

      <katapult-map-icons model-key="[[jobCreator]]"></katapult-map-icons>
      <span id="ruler"></span>

      <!-- Legend Editor Dialog -->
      <katapult-dialog-legacy
        id="legendEditor"
        style="width:600px;"
        fit-into="[[katapultMaps.$.mainHorizContainer]]"
        draggable
        no-cancel-on-esc-key=""
        no-cancel-on-outside-click=""
        entry-animation="scale-up-animation"
      >
        <div slot="title" secondary-color style="text-transform:uppercase;">Legend - Rename or Remove Items</div>
        <div slot="body">
          <template is="dom-repeat" items="{{legendItems}}">
            <div class="legendItem">
              <div class="legendIconContainer">
                <iron-icon icon="[[item.icon]]" src="[[item.iconSrc]]" style="[[item.style]]"></iron-icon>
              </div>
              <paper-input
                style="flex-grow:1;"
                label="[[item.description]]"
                value="{{item.label}}"
                disabled="[[item.removed]]"
              ></paper-input>
              <template is="dom-if" if="[[!item.removed]]">
                <katapult-button
                  id="legendItemRemove[[index]]"
                  noBorder
                  iconOnly
                  icon="close"
                  on-click="toggleLegendItem"
                ></katapult-button>
                <paper-tooltip position="left" for="legendItemRemove[[index]]">Remove Legend Item</paper-tooltip>
              </template>
              <template is="dom-if" if="[[item.removed]]">
                <div class="strikethrough"></div>
                <katapult-button id="legendItemAdd[[index]]" noBorder iconOnly icon="add" on-click="toggleLegendItem"></katapult-button>
                <paper-tooltip position="left" for="legendItemAdd[[index]]">Add Legend Item</paper-tooltip>
              </template>
            </div>
          </template>
        </div>
        <div slot="buttons">
          <katapult-button dialog-dismiss>Cancel</katapult-button>
          <katapult-button color="var(--secondary-color)" dialog-confirm on-click="saveLegend">Done</katapult-button>
        </div>
      </katapult-dialog-legacy>

      <!-- K logic editor dialog -->
      <katapult-dialog-legacy
        id="kLogicEditorDialog"
        fit-into="[[katapultMaps.$.mainHorizContainer]]"
        draggable
        min-width="500"
        no-cancel-on-esc-key=""
        no-cancel-on-outside-click=""
        entry-animation="scale-up-animation"
      >
        <div slot="title" secondary-color style="text-transform:uppercase;">Custom Filter Logic</div>
        <div slot="body" style="display: flex; flex-direction: column; gap: 16px;">
          <div style="display: flex; align-items: center; gap: 8px;">
            <material-icon icon="filter_alt"></material-icon>
            <div style="font-size: 16px; font-weight: 500;">Seed Condition</div>
          </div>
          <div>"[[camelCase(editingField.attribute)]]" will only be seeded if the following condition is TRUE.</div>
          <k-logic-editor schema="[[_filterLogicSchema]]" block="{{editingField.filter}}" expand-editor></k-logic-editor>
        </div>
        <div slot="buttons">
          <katapult-button dialog-dismiss>Cancel</katapult-button>
          <katapult-button color="var(--secondary-color)" dialog-confirm on-click="saveFilter">Save</katapult-button>
        </div>
      </katapult-dialog-legacy>
      <!--Custom Scale Dialog-->
      <paper-dialog
        id="customScaleDialog"
        style="max-width:450px;"
        no-cancel-on-esc-key=""
        no-cancel-on-outside-click=""
        entry-animation="scale-up-animation"
      >
        <div title="" secondary-color="">set custom scale</div>
        <div body="">
          <p>Enter a custom map scale.</p>
          <div id="customScaleInput">
            <paper-input style="width: 100px" label="Feet" value="{{customScale}}"></paper-input>
            <span> = 1"</span>
          </div>
        </div>
        <div class="scaleButtons">
          <katapult-button dialog-dismiss>Cancel</katapult-button>
          <katapult-button color="var(--secondary-color)" on-click="setCustomScale">Set Scale</katapult-button>
        </div>
      </paper-dialog>

      <!--Preview Dialog-->
      <katapult-dialog-legacy
        id="previewDialog"
        dynamic-resize
        fit-into="[[katapultMaps.$.mainHorizContainer]]"
        draggable
        no-cancel-on-esc-key=""
        no-cancel-on-outside-click=""
      >
        <div slot="title" secondary-color>
          <span>MAP PRINT PREVIEW</span>
        </div>
        <div id="contentArea" slot="body"></div>
        <template is="dom-if" if="[[previewError]]">
          <p slot="body" style="text-align:center;">[[previewError]]</p>
        </template>
        <paper-spinner slot="body" style="margin-left: calc(50% - 14px);" active="[[loadingPreview]]"></paper-spinner>
        <div slot="buttons" style="display:flex; width:100%; gap: 8px;">
          <template is="dom-if" if="[[!previewError]]">
            <katapult-button id="downloadFormButton" on-click="downloadFullPDF" style="color:var(--primary-color)"
              >Download Full PDF</katapult-button
            >
          </template>
          <div style="flex-grow: 1;"></div>
          <katapult-button dialog-dismiss>Close</katapult-button>
          <katapult-button color="var(--secondary-color)" on-click="updatePreview">Refresh</katapult-button>
        </div>
      </katapult-dialog-legacy>
      <!--End Preview Dialog-->

      <!--Change Feet Per Inch Dialog-->
      <paper-dialog
        id="changeFeetPerInchDialog"
        style="max-width:450px;"
        no-cancel-on-esc-key=""
        no-cancel-on-outside-click=""
        entry-animation="scale-up-animation"
      >
        <div title="" secondary-color="">
          <span>Change Scale?</span>
        </div>
        <div body="">
          <p>
            Are you sure you want to change the scale? This will change the size of all of your previously drawn map pages and annotations.
          </p>
        </div>
        <div class="buttons">
          <katapult-button dialog-dismiss>Cancel</katapult-button>
          <katapult-button color="var(--secondary-color)" on-click="changeFeetPerInch">Change</katapult-button>
        </div>
      </paper-dialog>
      <!--End Change Feet Per Inch Dialog-->

      <!--Map Print Config Settings Dialog-->
      <paper-dialog
        id="mapPrintConfigSettingsDialog"
        style="min-width:450px;"
        no-cancel-on-esc-key=""
        no-cancel-on-outside-click=""
        entry-animation="scale-up-animation"
      >
        <div title="" secondary-color="">
          <iron-icon icon="settings"></iron-icon>
          <span>Map Print Config Settings</span>
        </div>
        <div body="">
          <paper-input label="Map Print Config Name" value="{{currentMapPrintConfig.name}}"></paper-input>
        </div>
        <div buttons="">
          <katapult-button color="var(--paper-red-500)" dialog-dismiss on-click="openDeleteMapPrintDialog"
            >Delete From Job...</katapult-button
          >
          <div style="flex-grow: 1"></div>
          <katapult-button dialog-dismiss>Close</katapult-button>
          <katapult-button color="var(--secondary-color)" dialog-dismiss on-click="openSaveAsDialog">Save as Template...</katapult-button>
        </div>
      </paper-dialog>

      <!--Delete Map Print Config Dialog-->
      <paper-dialog id="deleteMapPrintDialog" style="min-width: 450px;" entry-animation="scale-up-animation">
        <div title="" red="">
          <iron-icon icon="delete"></iron-icon>
          <span>Delete Map Print Config?</span>
        </div>
        <div body="">
          <p>Are you sure you want to delete "[[currentMapPrintConfig.name]]" from this job? This action cannot be undone.</p>
        </div>
        <div buttons="">
          <katapult-button dialog-dismiss>Cancel</katapult-button>
          <katapult-button color="var(--paper-red-500)" dialog-dismiss on-click="deleteMapPrintConfig">Delete</katapult-button>
        </div>
      </paper-dialog>

      <!--Create New Config Dialog-->
      <paper-dialog
        id="createNewConfigDialog"
        style="max-width:450px;"
        no-cancel-on-esc-key=""
        no-cancel-on-outside-click=""
        entry-animation="scale-up-animation"
      >
        <div title="" secondary-color="">create new map print config</div>
        <div body="">
          <p>
            Enter a name for the new map print config and choose a template to copy from. If you do not choose a template, a generic config
            will be used.
          </p>
          <paper-input class="newNameInput" label="Map Print Config Name" value="{{newConfigName}}"></paper-input>
          <template is="dom-if" if="[[emptyObject(companyMapPrintTemplates)]]">
            <p>
              There are no existing map print templates to choose from. Create a generic config first, and then you can save it as a
              template.
            </p>
          </template>
          <template is="dom-if" if="[[!emptyObject(companyMapPrintTemplates)]]">
            <katapult-drop-down
              label="Choose From Template"
              items="[[companyMapPrintTemplates]]"
              label-path="name"
              value-path="$key"
              value="{{mapPrintTemplatesKeyToLoad}}"
            >
              <paper-item on-click="manageMapPrintTemplates">Manage Map Print Templates</paper-item>
            </katapult-drop-down>
          </template>
        </div>
        <div class="buttons">
          <katapult-button dialog-dismiss>Cancel</katapult-button>
          <katapult-button color="var(--secondary-color)" on-click="createNewConfig">Create</katapult-button>
        </div>
      </paper-dialog>
      <!--Create New Config Dialog-->

      <!--Save As Dialog-->
      <paper-dialog
        id="saveAsDialog"
        style="min-width:450px;"
        no-cancel-on-esc-key=""
        no-cancel-on-outside-click=""
        entry-animation="scale-up-animation"
      >
        <div title="" secondary-color="">Save as a Map Print Template</div>
        <div body="">
          <paper-radio-group selected="{{saveAsType}}">
            <paper-radio-button name="new">Save as New Template</paper-radio-button>
            <paper-radio-button name="existing">Save Over Existing Template</paper-radio-button>
          </paper-radio-group>
          <template is="dom-if" if="{{equal(saveAsType, 'new')}}">
            <paper-input class="newNameInput" label="New Template Name" value="{{newTemplateName}}"></paper-input>
          </template>
          <template is="dom-if" if="{{equal(saveAsType, 'existing')}}">
            <template is="dom-if" if="[[emptyObject(companyMapPrintTemplates)]]">
              <p>There are no existing map print templates.</p>
            </template>
            <template is="dom-if" if="[[!emptyObject(companyMapPrintTemplates)]]">
              <katapult-drop-down
                label="Choose a Template to Overwrite"
                items="[[companyMapPrintTemplates]]"
                label-path="name"
                value-path="$key"
                value="{{mapPrintTemplatesKeyToSaveAs}}"
              >
                <paper-item on-click="manageMapPrintTemplates">Manage Map Print Templates</paper-item>
              </katapult-drop-down>
            </template>
          </template>
        </div>
        <div class="buttons">
          <katapult-button dialog-dismiss>Cancel</katapult-button>
          <katapult-button color="var(--secondary-color)" on-click="saveConfigAs">Save</katapult-button>
        </div>
      </paper-dialog>
      <!--End Save Dialog-->

      <!-- Confirm Dialog -->
      <katapult-dialog-legacy
        id="confirmDialog"
        style="width: 500px;"
        no-cancel-on-esc-key
        no-cancel-on-outside-click
        entry-animation="scale-up-animation"
        exit-animation="fade-out-animation"
      >
        <div slot="title" secondary-color>[[confirmTitle]]</div>
        <div slot="body">
          <div>[[confirmBody]]</div>
          <template is="dom-if" if="[[equal(confirmType, 'seedAnnotations')]]">
            <div style="margin:20px; display: flex; flex-direction: column;">
              <paper-checkbox class="overwriteCheckbox" checked="{{overwriteAnnotationPosition}}">
                <span>Annotation Position *</span>
              </paper-checkbox>
              <paper-checkbox class="overwriteCheckbox" checked="{{overwriteAnnotationStyle}}">Annotation Style</paper-checkbox>
              <paper-checkbox class="overwriteCheckbox" checked="{{overwriteAnnotationText}}">Annotation Text</paper-checkbox>
            </div>
            <div style="font-style:italic; margin:0 20px;">
              * In order to add or remove leaders to existing annotations, you must update annotation position.
            </div>
          </template>
        </div>
        <div slot="buttons">
          <katapult-button on-click="confirmCancel">Cancel</katapult-button>
          <katapult-button loading="[[confirmDialogLoading]]" color="var(--secondary-color)" on-click="confirmAccepted"
            >[[confirmButtonText]]</katapult-button
          >
        </div>
      </katapult-dialog-legacy>
      <!-- End Confirm Dialog -->

      <div id="toolsContainer">
        <div id="mainHeader" raised$="[[toolsContainerScrolled]]">
          <katapult-button
            color="var(--secondary-color)"
            noBorder
            iconOnly
            style="position: absolute; top: 4px; right: 4px;"
            icon="clear"
            on-click="close"
          ></katapult-button>
          <div class="header">
            <iron-icon icon="print"></iron-icon>
            <span>Print Tools</span>
          </div>
          <div class="row">
            <katapult-drop-down
              id="mapPrintConfigChooser"
              label="Choose Map Print Config"
              no-label-float=""
              items="[[jobMapPrintConfigs]]"
              label-path="name"
              value-path="$key"
              value="{{currentMapPrintConfigsKey}}"
              on-selected-will-change="turnOffMapLayers"
            >
              <paper-item on-click="openCreateNewConfigDialog">CREATE NEW</paper-item>
            </katapult-drop-down>
            <template is="dom-if" if="[[currentMapPrintConfigsKey]]">
              <katapult-button
                noBorder
                iconOnly
                color="var(--secondary-color)"
                icon="settings"
                on-click="openMapPrintConfigSettingsDialog"
              ></katapult-button>
            </template>
          </div>
          <template is="dom-if" if="[[currentMapPrintConfigsKey]]">
            <div class="row">
              <katapult-button color="var(--secondary-color)" style="border-color: white" on-click="tappedShowPreview"
                >Preview</katapult-button
              >
            </div>
          </template>
        </div>
        <div id="scrollContainer" on-scroll="scrollContainerScrollEvent" katapult-drop-down-scroll-target="" katapult-drop-down-fit-into="">
          <template is="dom-if" if="[[currentMapPrintConfig.model]]">
            <!-- Document Settings section -->
            <div class="toolSection">
              <div class="header" name="documentSettings" on-click="toggleCollapse" active$="[[collapsibleSections.documentSettings]]">
                <span class="title">Document Settings</span>
                <iron-icon class="collapseIcon" icon="hardware:keyboard-arrow-down"></iron-icon>
              </div>
              <iron-collapse opened="[[collapsibleSections.documentSettings]]">
                <paper-table>
                  <paper-row style="height: auto;">
                    <paper-cell class="label">Layout</paper-cell>
                    <paper-radio-group selected="{{currentMapPrintConfig.model.paperSpace.orientation}}">
                      <paper-radio-button name="portrait">Portrait</paper-radio-button>
                      <paper-radio-button name="landscape">Landscape</paper-radio-button>
                    </paper-radio-group>
                  </paper-row>
                  <paper-row>
                    <paper-cell class="label">Width</paper-cell>
                    <paper-cell>
                      <paper-input
                        min="8.5"
                        max="24"
                        type="number"
                        no-label-float=""
                        value="{{currentMapPrintConfig.model.document.width}}"
                      ></paper-input>
                      <span>"</span>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell class="label">Height</paper-cell>
                    <paper-cell>
                      <paper-input
                        min="8.5"
                        max="24"
                        type="number"
                        no-label-float=""
                        value="{{currentMapPrintConfig.model.document.height}}"
                      ></paper-input>
                      <span>"</span>
                    </paper-cell>
                  </paper-row>
                </paper-table>
              </iron-collapse>
            </div>
            <!-- End Document Settings section -->

            <!-- Map Settings section -->
            <div class="toolSection">
              <div class="header" name="scaleSettings" on-click="toggleCollapse" active$="[[collapsibleSections.scaleSettings]]">
                <span class="title">Map Settings</span>
                <iron-icon class="collapseIcon" icon="hardware:keyboard-arrow-down"></iron-icon>
              </div>
              <iron-collapse opened="[[collapsibleSections.scaleSettings]]">
                <paper-table>
                  <paper-row>
                    <paper-cell class="label">Map Scale</paper-cell>
                    <paper-cell>
                      <katapult-drop-down
                        no-label-float=""
                        style="width: 90px;"
                        items="[[scales]]"
                        value="{{currentMapPrintConfig.model.feetPerInch}}"
                        on-selected-will-change="feetPerInchWillChange"
                        no-clear=""
                      >
                        <paper-item class="headerItem" on-click="openCustomScaleDialog">
                          <span>Custom Scale</span>
                        </paper-item>
                      </katapult-drop-down>
                      <span> = 1"</span>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell class="label">Icon Draw Scale</paper-cell>
                    <paper-cell>
                      <paper-input
                        style="width:50px;"
                        min="0.1"
                        max="5"
                        type="number"
                        no-label-float=""
                        value="{{currentMapPrintConfig.model.iconDrawScale}}"
                      ></paper-input>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell>
                      <katapult-button color="var(--secondary-color)" on-click="insertMapPage">Insert Map Page</katapult-button>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell>
                      <paper-checkbox id="pagesLayerOn" name="pages" checked="{{pagesLayerOn}}" on-click="selectLayer"></paper-checkbox>
                      <paper-tooltip for="pagesLayerOn" position="right" offset="10">Visibile</paper-tooltip>
                      <katapult-button
                        id="pagesLayerSelectable"
                        name="pages"
                        class="layerSelectableIcon"
                        noBorder
                        iconOnly
                        noBackground
                        style="margin-right: 3%;"
                        icon="arrow_selector_tool"
                        text-color="[[if(pagesLayerSelectable, 'black', 'var(--paper-grey-400)')]]"
                        on-click="toggleSelectable"
                      ></katapult-button>
                      <paper-tooltip for="pagesLayerSelectable" position="right" offset="10">Selectable</paper-tooltip>
                      <span>Map Pages</span>
                    </paper-cell>
                  </paper-row>
                </paper-table>
              </iron-collapse>
            </div>
            <!-- End Map Settings section -->

            <div class="toolSection">
              <div class="header" name="savedViews" on-click="toggleCollapse" active$="[[collapsibleSections.savedViews]]">
                <span class="title">Map Layer Settings</span>
                <iron-icon class="collapseIcon" icon="hardware:keyboard-arrow-down"></iron-icon>
              </div>
              <iron-collapse opened="[[collapsibleSections.savedViews]]">
                <paper-table>
                  <paper-row>
                    <paper-cell>
                      <katapult-drop-down
                        style="flex-grow: 1;"
                        items="[[savedViews]]"
                        label-path="name"
                        value-path="$key"
                        value="{{currentMapPrintConfig.model.savedView}}"
                        label="Saved View"
                      >
                        <paper-item on-click="manageSavedViews">Manage Saved Views</paper-item>
                      </katapult-drop-down>
                    </paper-cell>
                    <paper-cell icon>
                      <iron-icon id="savedViewHelp" style="margin-top: 15px;" icon="help"></iron-icon>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell>
                      <katapult-drop-down
                        style="flex-grow: 1;"
                        items="[[companyMapStyles]]"
                        label-path="_name"
                        value-path="$key"
                        value="{{currentMapPrintConfig.model.map_styles}}"
                        label="Map Styles"
                      >
                      </katapult-drop-down>
                    </paper-cell>
                    <paper-cell icon>
                      <iron-icon id="mapStylesHelp" style="margin-top: 15px;" icon="help"></iron-icon>
                    </paper-cell>
                  </paper-row>
                </paper-table>
              </iron-collapse>
              <paper-tooltip for="savedViewHelp" style="max-width:300px;" position="left"
                >A saved view can be used to configure or filter the layers visible on this map print.</paper-tooltip
              >
              <paper-tooltip for="mapStylesHelp" style="max-width:300px;" position="left">
                <div>Other map styles can be selected for display on this map print.</div>
                <br />
                <div>If none is selected, job styles are used by default.</div>
              </paper-tooltip>
            </div>

            <div class="toolSection">
              <div class="header" name="titleBlockSettings" on-click="toggleCollapse" active$="[[collapsibleSections.titleBlockSettings]]">
                <span class="title">Title Block Settings</span>
                <iron-icon class="collapseIcon" icon="hardware:keyboard-arrow-down"></iron-icon>
              </div>
              <iron-collapse opened="[[collapsibleSections.titleBlockSettings]]">
                <paper-table>
                  <paper-table-scroll style="overflow:visible;" on-sort-changed="titleBlockFieldsSortChanged">
                    <template is="dom-repeat" items="{{currentMapPrintConfig.model.titleBlock.fields}}" as="picklistItem">
                      <paper-row slot="sortable">
                        <paper-cell>
                          <iron-icon drag-handle="" class="picklistDragHandle" icon="katapult-misc:drag-indicator"></iron-icon>
                        </paper-cell>
                        <paper-input
                          label="[[camelCase(picklistItem.attribute)]]"
                          value="{{picklistItem.label}}"
                          style="flex-grow:1;"
                        ></paper-input>
                        <paper-cell icon-button
                          ><katapult-button noBorder iconOnly icon="close" on-click="removeTitleBlockField"></katapult-button
                        ></paper-cell>
                        <paper-cell icon-button>
                          <template is="dom-if" if="[[attributeType(picklistItem.attribute, 'node', otherAttributes)]]">
                            <paper-menu-button horizontal-align="right" horizontal-offset="36" vertical-align="top">
                              <katapult-button noBorder iconOnly icon="settings" slot="dropdown-trigger"></katapult-button>
                              <div slot="dropdown-content" style="margin:5px;">
                                <paper-radio-group
                                  selected="{{picklistItem.type}}"
                                  fallback-selection="jobAttribute"
                                  style="display: flex; flex-direction: column;"
                                >
                                  <template is="dom-if" if="[[attributeType(picklistItem.attribute, 'job', otherAttributes)]]">
                                    <paper-radio-button name="jobAttribute">Job Attribute</paper-radio-button>
                                  </template>
                                  <paper-radio-button name="firstNodeValue">First Value On Page</paper-radio-button>
                                  <paper-radio-button name="allNodeValues">All Values On Page</paper-radio-button>
                                </paper-radio-group>
                              </div>
                            </paper-menu-button>
                          </template>
                        </paper-cell>
                      </paper-row>
                    </template>
                  </paper-table-scroll>
                  <paper-row>
                    <paper-cell>
                      <katapult-drop-down
                        style="flex-grow: 1;"
                        label="Add Field"
                        items="[[titleBlockFields]]"
                        label-path="label"
                        value-path="attribute"
                        no-label-float=""
                        on-selected-changed="addTitleBlockField"
                      ></katapult-drop-down>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell>
                      <paper-checkbox checked="{{currentMapPrintConfig.model.titleBlock.includeLabels}}">Include Labels</paper-checkbox>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell class="label">Text Color</paper-cell>
                    <paper-cell>
                      <katapult-color-picker-button
                        style="display:inline-block;margin:0 0 0 8px;"
                        color="{{currentMapPrintConfig.model.titleBlock.textColor}}"
                      ></katapult-color-picker-button>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell class="label">Text Size</paper-cell>
                    <paper-cell>
                      <paper-input
                        style="width:50px;"
                        min="8"
                        max="50"
                        type="number"
                        no-label-float=""
                        value="{{currentMapPrintConfig.model.titleBlock.textSize}}"
                      ></paper-input>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell class="label">Item Padding</paper-cell>
                    <paper-cell>
                      <paper-input
                        style="width:50px;"
                        type="number"
                        no-label-float
                        value="{{currentMapPrintConfig.model.titleBlock.itemPadding}}"
                      ></paper-input>
                    </paper-cell>
                  </paper-row>
                </paper-table>
              </iron-collapse>
            </div>

            <div class="toolSection">
              <div class="header" name="legendSettings" on-click="toggleCollapse" active$="[[collapsibleSections.legendSettings]]">
                <span class="title">Legend Settings</span>
                <iron-icon class="collapseIcon" icon="hardware:keyboard-arrow-down"></iron-icon>
              </div>
              <iron-collapse opened="[[collapsibleSections.legendSettings]]">
                <paper-table>
                  <paper-row>
                    <paper-cell class="label">Text Color</paper-cell>
                    <paper-cell>
                      <katapult-color-picker-button
                        style="display:inline-block;margin:0 0 0 8px;"
                        color="{{currentMapPrintConfig.model.titleBlock.legend.textColor}}"
                      ></katapult-color-picker-button>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell class="label">Text Size</paper-cell>
                    <paper-cell>
                      <paper-input
                        style="width:50px;"
                        min="8"
                        max="50"
                        type="number"
                        no-label-float=""
                        value="{{currentMapPrintConfig.model.titleBlock.legend.textSize}}"
                      ></paper-input>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell>
                      <katapult-button color="var(--secondary-color)" on-click="openLegendEditor" icon="format_list_bulleted"
                        >Edit Legend</katapult-button
                      >
                    </paper-cell>
                  </paper-row>
                </paper-table>
              </iron-collapse>
            </div>

            <div class="toolSection">
              <div class="header" name="paperSpaceSettings" on-click="toggleCollapse" active$="[[collapsibleSections.paperSpaceSettings]]">
                <span class="title">Paper Space Settings</span>
                <iron-icon class="collapseIcon" icon="hardware:keyboard-arrow-down"></iron-icon>
              </div>
              <iron-collapse opened="[[collapsibleSections.paperSpaceSettings]]">
                <paper-table>
                  <paper-row>
                    <paper-cell class="label">Primary Color</paper-cell>
                    <paper-cell>
                      <katapult-color-picker-button
                        style="display:inline-block;margin:0 0 0 8px;"
                        color="{{currentMapPrintConfig.model.paperSpace.primaryColor}}"
                      ></katapult-color-picker-button>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell class="label">Secondary Color</paper-cell>
                    <paper-cell>
                      <katapult-color-picker-button
                        style="display:inline-block;margin:0 0 0 8px;"
                        color="{{currentMapPrintConfig.model.paperSpace.secondaryColor}}"
                      ></katapult-color-picker-button>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell>
                      <katapult-drop-down
                        style="flex-grow: 1;"
                        no-label-float=""
                        label="Choose Logo"
                        items="[[files]]"
                        value="{{currentMapPrintConfig.model.paperSpace.logoFile}}"
                        label-path="name"
                        value-path="$key"
                      >
                        <paper-item on-click="manageLogos">Manage Logos</paper-item>
                      </katapult-drop-down>
                    </paper-cell>
                  </paper-row>
                </paper-table>
              </iron-collapse>
            </div>

            <div class="toolSection">
              <div class="header" name="additionalInfo" on-click="toggleCollapse" active$="[[collapsibleSections.additionalInfo]]">
                <span class="title">Additional Settings</span>
                <iron-icon class="collapseIcon" icon="hardware:keyboard-arrow-down"></iron-icon>
              </div>
              <iron-collapse opened="[[collapsibleSections.additionalInfo]]">
                <paper-table>
                  <paper-row>
                    <paper-cell>
                      <paper-checkbox style="margin-left: 10px color: white" checked="{{currentMapPrintConfig.model.includeNodeInfo}}"
                        >Include Node Info</paper-checkbox
                      >
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell>
                      <paper-checkbox style="margin-left: 10px color: white" checked="{{currentMapPrintConfig.model.includeOverviewSheet}}"
                        >Include Overview Sheet</paper-checkbox
                      >
                    </paper-cell>
                  </paper-row>
                </paper-table>
              </iron-collapse>
            </div>

            <div class="toolSection">
              <div
                class="header"
                name="annotationStyleSettings"
                on-click="toggleCollapse"
                active$="[[collapsibleSections.annotationStyleSettings]]"
              >
                <span class="title">Annotation Style Settings</span>
                <iron-icon class="collapseIcon" icon="hardware:keyboard-arrow-down"></iron-icon>
              </div>
              <iron-collapse opened="[[collapsibleSections.annotationStyleSettings]]">
                <paper-table>
                  <paper-row>
                    <paper-cell>
                      <paper-dropdown-menu label="Background Shape">
                        <paper-listbox
                          slot="dropdown-content"
                          attr-for-selected="name"
                          selected="{{currentMapPrintConfig.model.annotations.backgroundShape}}"
                        >
                          <template is="dom-repeat" items="[[annotationBackgroundShapes]]">
                            <paper-item name="{{item.icon}}" style="padding:8px;">
                              <iron-icon
                                style="margin-right:8px; --iron-icon-stroke-color:{{currentMapPrintConfig.model.annotations.outlineColor}}; --iron-icon-fill-color:{{currentMapPrintConfig.model.annotations.boxColor}}"
                                icon="{{item.icon}}"
                              ></iron-icon>
                              <span>{{item.title}}</span>
                            </paper-item>
                          </template>
                        </paper-listbox>
                      </paper-dropdown-menu>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell class="label">Text Color</paper-cell>
                    <paper-cell>
                      <katapult-color-picker-button
                        style="display:inline-block;margin:0 0 0 8px;"
                        color="{{currentMapPrintConfig.model.annotations.textColor}}"
                      ></katapult-color-picker-button>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell class="label">Text Size</paper-cell>
                    <paper-cell>
                      <paper-input
                        style="width:50px;"
                        min="8"
                        max="50"
                        type="number"
                        no-label-float=""
                        value="{{currentMapPrintConfig.model.annotations.textSize}}"
                      ></paper-input>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell class="label">Background Color</paper-cell>
                    <paper-cell>
                      <katapult-color-picker-button
                        style="display:inline-block;margin:0 0 0 8px;"
                        color="{{currentMapPrintConfig.model.annotations.boxColor}}"
                      ></katapult-color-picker-button>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell class="label">Outline Color</paper-cell>
                    <paper-cell>
                      <katapult-color-picker-button
                        style="display:inline-block;margin:0 0 0 8px;"
                        color="{{currentMapPrintConfig.model.annotations.outlineColor}}"
                      ></katapult-color-picker-button>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell class="label">Outline Width</paper-cell>
                    <paper-cell>
                      <paper-input
                        style="width:50px;"
                        min="0"
                        max="10"
                        type="number"
                        no-label-float=""
                        value="{{currentMapPrintConfig.model.annotations.outlineWidth}}"
                      ></paper-input>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell class="label">Leaders</paper-cell>
                    <paper-cell>
                      <paper-checkbox checked="{{currentMapPrintConfig.model.annotations.leader}}"></paper-checkbox>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell class="label">Leader Color</paper-cell>
                    <paper-cell>
                      <katapult-color-picker-button
                        style="display:inline-block;margin:0 0 0 8px;"
                        color="{{currentMapPrintConfig.model.annotations.leaderColor}}"
                      ></katapult-color-picker-button>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell class="label">Arrow Endpoint</paper-cell>
                    <paper-cell>
                      <paper-checkbox checked="{{currentMapPrintConfig.model.annotations.arrowEndpoint}}"></paper-checkbox>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell>
                      <katapult-button color="var(--secondary-color)" on-click="insertAnnotation">Insert Annotation</katapult-button>
                    </paper-cell>
                  </paper-row>
                  <paper-row>
                    <paper-cell>
                      <paper-checkbox name="annotations" checked="{{annotationsLayerOn}}" on-click="selectLayer"></paper-checkbox>
                      <katapult-button
                        id="annotationsLayerSelectable"
                        name="annotations"
                        class="layerSelectableIcon"
                        noBorder
                        iconOnly
                        style="margin-right: 3%;"
                        icon="arrow_selector_tool"
                        text-color="[[if(annotationsLayerSelectable, 'black', 'var(--paper-grey-400)')]]"
                        on-click="toggleSelectable"
                      ></katapult-button>
                      <paper-tooltip for="annotationsLayerSelectable" position="right" offset="10">Selectable</paper-tooltip>
                      <span>Annotations</span>
                    </paper-cell>
                  </paper-row>
                </paper-table>
              </iron-collapse>
            </div>

            <iron-collapse opened="[[collapsibleSections.nodeAttributes]]">
              <div class="toolSection">
                <div class="header" name="nodeAttributes" on-click="toggleCollapse" active$="[[collapsibleSections.nodeAttributes]]">
                  <span class="title">Node Annotations</span>
                  <iron-icon class="collapseIcon" icon="hardware:keyboard-arrow-down"></iron-icon>
                </div>
                <iron-collapse opened="[[collapsibleSections.nodeAttributes]]">
                  <paper-table>
                    <paper-table-scroll style="overflow:visible;" on-sort-changed="nodeAttributesSortChanged">
                      <template is="dom-repeat" items="{{currentMapPrintConfig.model.annotations.fields}}" as="field">
                        <paper-row slot="sortable">
                          <paper-cell>
                            <iron-icon drag-handle="" class="picklistDragHandle" icon="katapult-misc:drag-indicator"></iron-icon>
                          </paper-cell>
                          <paper-input label="[[camelCase(field.attribute)]]" value="{{field.label}}" style="flex-grow:1;"></paper-input>
                          <paper-cell icon-button
                            ><katapult-button noBorder iconOnly icon="close" on-click="removeNodeAttribute"></katapult-button
                          ></paper-cell>
                          <paper-cell icon-button>
                            <paper-menu-button
                              id="annotationMenu[[index]]"
                              horizontal-align="right"
                              horizontal-offset="36"
                              vertical-align="top"
                            >
                              <katapult-button noBorder iconOnly icon="settings" slot="dropdown-trigger"></katapult-button>
                              <div
                                slot="dropdown-content"
                                style="display: flex; flex-direction: column; padding: 15px; user-select: none; white-space: nowrap; min-width: 200px; box-sizing: border-box;"
                              >
                                <div style="display: flex; align-items: center;">
                                  <span>Custom Filter Logic:</span>
                                  <katapult-button
                                    class="klogicButton"
                                    color="[[if(field.filter, 'var(--paper-teal-800)', 'white')]]"
                                    noBorder
                                    iconOnly
                                    on-click="openKLogicEditor"
                                    style="display: flex; width: fit-content; flex-direction: row-reverse;"
                                    icon="functions"
                                    on-click="openKLogicEditor"
                                  ></katapult-button>
                                </div>
                                <template is="dom-if" if="[[equal(field.attribute, 'pole_tag')]]">
                                  <div>
                                    <paper-checkbox checked="{{field.hide_pole_tag_company}}">Hide Company Names</paper-checkbox>
                                  </div>
                                </template>
                              </div>
                            </paper-menu-button>
                          </paper-cell>
                        </paper-row>
                      </template>
                    </paper-table-scroll>
                    <paper-row>
                      <paper-cell style="max-width: 30%">Attribute Annotations:</paper-cell>
                      <paper-cell>
                        <katapult-drop-down
                          style="flex-grow: 1;"
                          label="Add annotation"
                          items="[[nodeSeedAttributeAnnotations]]"
                          no-label-float=""
                          on-selected-changed="addNodeAttribute"
                          label-function="[[camelCase]]"
                        ></katapult-drop-down>
                      </paper-cell>
                    </paper-row>
                    <template is="dom-if" if="[[seedComputedAnnotations]]">
                      <paper-row>
                        <paper-cell style="max-width: 30%">Computed Annotations:</paper-cell>
                        <paper-cell>
                          <katapult-drop-down
                            style="flex-grow: 1;"
                            label="Add annotation"
                            items="[[seedComputedAnnotations]]"
                            no-label-float=""
                            value-path="key"
                            label-path="name"
                            on-selected-changed="addComputedAttribute"
                          ></katapult-drop-down>
                        </paper-cell>
                      </paper-row>
                    </template>
                    <template is="dom-if" if="[[!enabledFeatures.display_connection_seeding_for_map_prints]]">
                      <paper-row>
                        <paper-cell>
                          <paper-checkbox checked="{{currentMapPrintConfig.model.annotations.includeSpanDistance}}"
                            >Span Distances</paper-checkbox
                          >
                        </paper-cell>
                      </paper-row>
                    </template>
                    <paper-row>
                      <paper-cell>
                        <paper-checkbox checked="{{currentMapPrintConfig.model.annotations.includeAttributeLabels}}"
                          >Include Attribute Labels</paper-checkbox
                        >
                      </paper-cell>
                    </paper-row>
                    <paper-row expand style="padding:0 10px; text-align:center; gap:8px;">
                      <katapult-button
                        color="var(--paper-orange-500)"
                        id="seedAnnotations"
                        on-click="seedAnnotations"
                        loading="[[preppingForSeedAnnotations]]"
                        >Seed Annotations...</katapult-button
                      >
                      <katapult-button color="var(--secondary-color)" id="clearAnnotations" on-click="clearAnnotations"
                        >Clear Annotations...</katapult-button
                      >
                    </paper-row>
                  </paper-table>
                </iron-collapse>
              </div>

              <!-- connection annotations -->
              <template is="dom-if" if="[[enabledFeatures.display_connection_seeding_for_map_prints]]">
                <div class="toolSection">
                  <div
                    class="header"
                    name="connectionAttributes"
                    on-click="toggleCollapse"
                    active$="[[collapsibleSections.connectionAttributes]]"
                  >
                    <span class="title">Connection Annotations</span>
                    <iron-icon class="collapseIcon" icon="hardware:keyboard-arrow-down"></iron-icon>
                  </div>
                  <iron-collapse opened="[[collapsibleSections.connectionAttributes]]">
                    <paper-table>
                      <paper-table-scroll style="overflow:visible;" on-sort-changed="nodeAttributesSortChanged">
                        <template is="dom-repeat" items="{{currentMapPrintConfig.model.annotations.connectionFields}}" as="field">
                          <paper-row slot="sortable">
                            <paper-cell>
                              <iron-icon drag-handle="" class="picklistDragHandle" icon="katapult-misc:drag-indicator"></iron-icon>
                            </paper-cell>
                            <paper-input label="[[camelCase(field.attribute)]]" value="{{field.label}}" style="flex-grow:1;"></paper-input>
                            <paper-cell icon-button
                              ><katapult-button noBorder iconOnly icon="close" on-click="removeConnectionAttribute"></katapult-button
                            ></paper-cell>
                            <paper-cell icon-button>
                              <paper-menu-button
                                id="annotationMenu[[index]]"
                                horizontal-align="right"
                                horizontal-offset="36"
                                vertical-align="top"
                              >
                                <katapult-button noBorder iconOnly icon="settings" slot="dropdown-trigger"></katapult-button>
                                <div
                                  slot="dropdown-content"
                                  style="display: flex; flex-direction: column; padding: 15px; user-select: none; white-space: nowrap; min-width: 200px; box-sizing: border-box;"
                                >
                                  <div style="display: flex; align-items: center;">
                                    <span>Custom Filter Logic:</span>
                                    <katapult-button
                                      class="klogicButton"
                                      color="[[if(field.filter, 'var(--paper-teal-800)', 'white')]]"
                                      noBorder
                                      iconOnly
                                      on-click="openKLogicEditorConn"
                                      style="display: flex; width: fit-content; flex-direction: row-reverse;"
                                      icon="functions"
                                      on-click="openKLogicEditorConn"
                                    ></katapult-button>
                                  </div>
                                  <template is="dom-if" if="[[equal(connField.attribute, 'pole_tag')]]">
                                    <div>
                                      <paper-checkbox checked="{{connField.hide_pole_tag_company}}">Hide Company Names</paper-checkbox>
                                    </div>
                                  </template>
                                </div>
                              </paper-menu-button>
                            </paper-cell>
                          </paper-row>
                        </template>
                      </paper-table-scroll>
                      <paper-row>
                        <paper-cell style="max-width: 30%">Attribute Annotations:</paper-cell>
                        <paper-cell>
                          <katapult-drop-down
                            style="flex-grow: 1;"
                            label="Add annotation"
                            items="[[connSeedAttributeAnnotations]]"
                            no-label-float=""
                            on-selected-changed="addConnectionAttribute"
                            label-function="[[camelCase]]"
                          ></katapult-drop-down>
                        </paper-cell>
                      </paper-row>
                      <template is="dom-if" if="[[seedComputedAnnotations]]">
                        <paper-row>
                          <paper-cell style="max-width: 30%">Computed Annotations:</paper-cell>
                          <paper-cell>
                            <katapult-drop-down
                              style="flex-grow: 1;"
                              label="Add annotation"
                              items="[[seedComputedAnnotations]]"
                              no-label-float=""
                              value-path="key"
                              label-path="name"
                              on-selected-changed="addConnComputedAttribute"
                            ></katapult-drop-down>
                          </paper-cell>
                        </paper-row>
                      </template>
                      <paper-row>
                        <paper-cell>
                          <paper-checkbox checked="{{currentMapPrintConfig.model.annotations.includeSpanDistance}}"
                            >Span Distances</paper-checkbox
                          >
                        </paper-cell>
                      </paper-row>
                      <paper-row>
                        <paper-cell>
                          <paper-checkbox checked="{{currentMapPrintConfig.model.annotations.includeAttributeLabels}}"
                            >Include Attribute Labels</paper-checkbox
                          >
                        </paper-cell>
                      </paper-row>
                      <paper-row expand style="padding:0 10px; text-align:center; gap:8px;">
                        <katapult-button
                          color="var(--paper-orange-500)"
                          id="seedConnectionAnnotations"
                          on-click="seedConnectionAnnotations"
                          loading="[[preppingForSeedConnAnnotations]]"
                          >Seed Annotations...</katapult-button
                        >
                        <katapult-button color="var(--secondary-color)" id="clearAnnotations" on-click="clearAnnotations"
                          >Clear Annotations...</katapult-button
                        >
                      </paper-row>
                    </paper-table>
                  </iron-collapse>
                </div>
              </template>
            </iron-collapse>

            <template is="dom-if" if="[[enabledFeatures.map_print_svg_templates]]">
              <div class="toolSection">
                <div
                  class="header"
                  name="elementsAndPositioning"
                  on-click="toggleCollapse"
                  active$="[[collapsibleSections.elementsAndPositioning]]"
                >
                  <span class="title"> Map Print Masks </span>
                  <iron-icon class="collapseIcon" icon="hardware:keyboard-arrow-down"></iron-icon>
                </div>
                <iron-collapse opened="[[collapsibleSections.elementsAndPositioning]]">
                  <paper-table>
                    <paper-row>
                      <paper-cell>
                        <paper-checkbox checked="{{currentMapPrintConfig.model.svgMask.useSVGMask}}"> Use Map Print Mask </paper-checkbox>
                      </paper-cell>
                    </paper-row>
                    <template is="dom-if" if="[[currentMapPrintConfig.model.svgMask.useSVGMask]]">
                      <paper-row>
                        <paper-cell>
                          <katapult-drop-down
                            style="flex-grow: 1;"
                            no-label-float=""
                            label="Choose SVG Mask"
                            items="[[svgFiles]]"
                            value="{{currentMapPrintConfig.model.svgMask.maskFile}}"
                            label-path="name"
                            value-path="$key"
                          >
                            <paper-item on-click="manageLogos">Manage SVGs</paper-item>
                          </katapult-drop-down>
                        </paper-cell>
                      </paper-row>
                    </template>
                    <object id="svgObject" data="[[svgMaskURL]]" type="image/svg+xml" style="display: none"></object>
                  </paper-table>
                </iron-collapse>
              </div>
            </template>
          </template>
        </div>
      </div>
    `;
  }

  static get is() {
    return 'print-mode-toolbar';
  }
  static get properties() {
    return {
      collapsibleSections: {
        type: Object,
        value: () => {
          return {
            documentSettings: true,
            scaleSettings: true,
            savedViews: true,
            annotationStyleSettings: true,
            titleBlockSettings: true,
            legendSettings: true,
            paperSpaceSettings: true,
            nodeAttributes: true,
            jobAttributes: true,
            additionalInfo: true,
            elementsAndPositioning: true
          };
        }
      },
      loadingPreview: {
        type: Boolean,
        value: false
      },
      modelDefaults: {
        type: Object,
        value: {}
      },
      currentMapPrintConfigsKey: {
        type: String,
        value: null,
        observer: 'currentMapPrintConfigsKeyChanged'
      },
      currentMapPrintConfig: {
        type: Object,
        value: null
      },
      pagesLayerOn: {
        type: Boolean,
        value: false,
        observer: 'togglePagesLayer'
      },
      annotationsLayerOn: {
        type: Boolean,
        value: false,
        observer: 'toggleAnnotationsLayer'
      },
      otherAttributes: {
        type: Object,
        observer: 'otherAttributesChanged'
      },
      mapPrintAnnotationLogic: {
        type: Object,
        observer: 'mapPrintAnnotationLogicChanged'
      },
      saveAsType: {
        type: String,
        value: 'new'
      },
      scales: {
        type: Array
      },
      annotationBackgroundShapes: {
        type: Array,
        computed: 'calcAnnotationBackgroundShapes(userGroup)'
      },
      showToolbar: {
        type: Boolean,
        notify: true,
        reflectToAttribute: true,
        computed: 'calcShowToolbar(viewMode)'
      },
      viewMode: {
        type: Boolean,
        notify: true
      },
      preppingForSeedAnnotations: {
        type: Boolean,
        value: false
      },
      preppingForSeedConnAnnotations: {
        type: Boolean,
        value: false
      },
      svgFiles: {
        type: Array,
        computed: 'filterSvgFiles(files)'
      },
      enabledFeatures: {
        type: Object,
        value: () => ({})
      }
    };
  }
  static get observers() {
    return [
      'calcPrintScales(currentMapPrintConfig.model.customFeetPerInch)',
      'updateConfigIfNeeded(currentMapPrintConfig)',
      'chooseFirstMapPrint(jobMapPrintConfigs, viewMode)',
      'setLocalMapStyles(viewMode, currentMapPrintConfig.model.map_styles, companyMapStylesLoading)'
    ];
  }
  constructor() {
    super();
    this.katapultMaps = this.pageElement;
    this._filterLogicSchema = { node: DEFAULT_SCHEMAS.NODE, attribute: 'any' };
  }
  ready() {
    super.ready();
    window.printModeToolbar = this;

    addFlagListener('map_print_svg_templates', (enabled) => {
      this.set('enabledFeatures.map_print_svg_templates', enabled);
    });

    // adding flag listener for feature flag
    addFlagListener('display_connection_seeding_for_map_prints', (enabled) => {
      this.set('enabledFeatures.display_connection_seeding_for_map_prints', enabled);
    });
  }
  async confirm({ title, body, type, confirmButtonText, keepOpen }) {
    return new Promise((resolve) => {
      this.confirmResolve = resolve;
      this.confirmType = type;
      this.confirmTitle = title;
      this.confirmBody = body;
      this.confirmButtonText = confirmButtonText || 'Confirm';
      this.confirmKeepOpen = keepOpen;
      this.$.confirmDialog.open();
    });
  }

  confirmAccepted(e) {
    if (!this.confirmKeepOpen) this.$.confirmDialog.close();
    this.confirmResolve(true);
  }

  confirmCancel() {
    this.$.confirmDialog.close();
    this.confirmResolve(false);
  }
  toggleCollapse(e) {
    let name = e.currentTarget.getAttribute('name');
    this.set(`collapsibleSections.${name}`, !this.get(`collapsibleSections.${name}`));
  }
  chooseFirstMapPrint(jobMapPrintConfigs, viewMode) {
    if (viewMode == 'print' && jobMapPrintConfigs && !this.currentMapPrintConfigsKey) {
      let keys = Object.keys(jobMapPrintConfigs);
      setTimeout(() => {
        if (viewMode == 'print' && jobMapPrintConfigs && !this.currentMapPrintConfigsKey) {
          if (keys.length == 1) {
            this.currentMapPrintConfigsKey = keys[0];
          } else {
            this.shadowRoot.querySelector('#mapPrintConfigChooser')?.focus();
          }
        }
      }, 300);
    }
  }
  // This function runs when the map print config is loaded.
  // It checks to see if there is any missing data that should
  // be set up with defaults, in case the user is loading an old
  // map print config.
  addMissingDefaultsToConfig(config) {
    if (config && config.model) {
      let defaultConfig = this.getGenericMapPrintConfig();
      // Set up missing title block settings
      if (!SquashNulls(config, 'model', 'titleBlock')) {
        config.model.titleBlock = defaultConfig.model.titleBlock;
      }
      // Add missing title block labels
      config.model.titleBlock.fields?.forEach((field) => {
        if (field.label == null) {
          field.label = CamelCase(field.attribute);
        }
      });
      // Add missing title block padding
      if (config?.model?.titleBlock?.itemPadding == null) {
        config.model.titleBlock.itemPadding = defaultConfig.model.titleBlock.itemPadding;
      }
      if (config?.model?.titleBlock?.textColor == null) {
        config.model.titleBlock.textColor = defaultConfig.model.titleBlock.textColor;
      }
      if (config?.model?.titleBlock?.textSize == null) {
        config.model.titleBlock.textSize = defaultConfig.model.titleBlock.textSize;
      }
      // Add missing margin
      if (!SquashNulls(config, 'model', 'document', 'margin')) {
        config.model.document.margin = defaultConfig.model.document.margin;
      }
      // Add missing icon scale
      if (!SquashNulls(config, 'model', 'iconDrawScale')) {
        config.model.iconDrawScale = defaultConfig.model.iconDrawScale;
      }
      // Add missing outline width
      if (!SquashNulls(config, 'model', 'annotations', 'outlineWidth')) {
        config.model.annotations.outlineWidth = defaultConfig.model.annotations.outlineWidth;
      }
      // Add missing leader setting
      if (config?.model?.annotations?.leader == null) {
        config.model.annotations.leader = defaultConfig.model.annotations.leader;
      }
      // Add missing leader arrowEndpoint
      if (config?.model?.annotations?.arrowEndpoint == null) {
        config.model.annotations.arrowEndpoint = defaultConfig.model.annotations.arrowEndpoint;
      }
      // Add missing leader color
      if (config?.model?.annotations?.leaderColor == null) {
        config.model.annotations.leaderColor = defaultConfig.model.annotations.leaderColor;
      }
      // Add missing svgMask data
      if (!config?.model?.svgMask) {
        config.model.svgMask = { useSVGMask: false, maskFile: null };
      }
      // Add missing annotation fields
      if (!SquashNulls(config, 'model', 'annotations', 'fields')) {
        let alreadyConvertedMRNotes = false;
        config.model.annotations.fields = config.model.annotations.defaultNodeAttributes
          ?.map((attribute) => {
            // Convert power or comm mr to make ready notes
            if (attribute == 'power_mr_notes' || attribute == 'com_mr_notes') {
              // If we already converted one, don't convert any more
              if (alreadyConvertedMRNotes) return;
              alreadyConvertedMRNotes = true;
              attribute = 'make_ready_notes';
            }
            return { attribute, label: CamelCase(attribute) };
          })
          .filter((x) => x);
      }
    }
  }
  updateConfigIfNeeded() {
    if (this.currentMapPrintConfig) {
      let configBefore = JSON.stringify(this.currentMapPrintConfig);
      this.addMissingDefaultsToConfig(this.currentMapPrintConfig);
      this.convertNumbers(this.currentMapPrintConfig);
      this.computeMapSize(this.currentMapPrintConfig);
      let configAfter = JSON.stringify(this.currentMapPrintConfig);
      if (configBefore != configAfter) {
        if (this.jobId && this.currentMapPrintConfigsKey) {
          FirebaseWorker.ref(`photoheight/jobs/${this.jobId}/map_print_configs/${this.currentMapPrintConfigsKey}`).set(
            JSON.parse(configAfter)
          );
        }
      }
    }
  }
  attributeType(attribute, type) {
    return this.otherAttributes?.[attribute]?.attribute_types?.includes(type);
  }
  convertNumbers(config) {
    // Make sure certain properties are numbers
    config.model.document.width = Number(config.model.document.width);
    config.model.document.height = Number(config.model.document.height);
    config.model.document.margin = Number(config.model.document.margin);
    config.model.document.resolution = Number(config.model.document.resolution);
    config.model.annotations.textSize = Number(config.model.annotations.textSize);
  }
  computeMapSize(config) {
    // Determine what should be the short size, depending on the orientation
    let shortSideSize = config.model.paperSpace.orientation == 'portrait' ? config.model.document.width : config.model.document.height;
    // Make the map print length fill the length of the short side
    config.model.map.width = shortSideSize - config.model.document.margin * 2;
    // Make the height and width the same
    config.model.map.height = config.model.map.width;
  }
  getGenericMapPrintConfig() {
    let genericConfig = {
      name: 'Untitled',
      model: {
        feetPerInch: `100'`,
        iconDrawScale: 0.5,
        document: {
          width: 8.5,
          height: 11,
          margin: 0.2,
          resolution: 100
        },
        map: {},
        titleBlock: {
          includeLabels: true,
          itemPadding: 5,
          textColor: '#FFFFFF',
          textSize: 12,
          // Use underscores to denote special fields
          fields: [
            {
              attribute: '_page_no'
            },
            {
              attribute: '_job_name'
            },
            {
              attribute: '_date'
            },
            {
              attribute: '_text'
            }
          ]
        },
        annotations: {
          textColor: 'rgba(0,0,0,1)',
          textSize: 12,
          boxColor: 'rgba(255,255,255,0.7)',
          outlineColor: 'rgba(0,0,0,1)',
          outlineWidth: 1,
          leader: false,
          arrowEndpoint: false,
          leaderColor: 'rgba(0,0,0,1)',
          // defaultNodeAttributes left for pre-6.0 versions of code. Can be removed in the future.
          defaultNodeAttributes: ['pole_tag', this.modelDefaults.ordering_attribute],
          fields: [
            { attribute: 'pole_tag', label: 'Pole Tag' },
            { attribute: this.modelDefaults.ordering_attribute, label: this.modelDefaults.ordering_attribute_label }
          ],
          includeSpanDistance: true,
          includeAttributeLabels: false
        },
        paperSpace: {
          primaryColor: '#007299',
          orientation: 'portrait'
        },
        includeOverviewSheet: false,
        includeNodeInfo: false
      }
    };
    this.computeMapSize(genericConfig);
    return genericConfig;
  }
  emptyObject(obj) {
    return Object.keys(obj || {}).length == 0;
  }
  manageMapPrintTemplates() {
    OpenPage('model-editor', {
      target: '_blank',
      hash: `${this.jobCreator}/map_print_templates`
    });
  }
  closeAllDialogs() {
    this.$.createNewConfigDialog.close();
    this.$.mapPrintConfigSettingsDialog.close();
    this.$.saveAsDialog.close();
    this.$.deleteMapPrintDialog.close();
    this.$.previewDialog.close();
    this.$.changeFeetPerInchDialog.close();
    this.$.customScaleDialog.close();
  }
  scrollContainerScrollEvent(e) {
    this.toolsContainerScrolled = e.currentTarget.scrollTop > 0;
  }
  openCreateNewConfigDialog() {
    this.closeAllDialogs();
    // Reset dialog data before opening
    this.newConfigName = null;
    this.mapPrintTemplatesKeyToLoad = null;
    this.$.createNewConfigDialog.open();
  }
  createNewConfig() {
    // Clear any existing warnings
    this.clearSaveWarning();
    if (this.newConfigName) {
      // Close any dialogs
      this.closeAllDialogs();
      if (this.jobId) {
        // Get a key to use for the new config
        let newKey = FirebaseWorker.ref(`/`).push().key;
        // Get a generic config object by default
        let newConfig = this.getGenericMapPrintConfig();
        // Check if we should load a config from a template
        if (this.mapPrintTemplatesKeyToLoad && this.companyMapPrintTemplates[this.mapPrintTemplatesKeyToLoad]) {
          newConfig = JSON.parse(JSON.stringify(this.companyMapPrintTemplates[this.mapPrintTemplatesKeyToLoad]));
          // Delete the $key property
          delete newConfig.$key;
        }
        // Set the name to the name chosen by the user
        newConfig.name = this.newConfigName || 'Untitled';

        // Set the config on the job
        FirebaseWorker.ref(`/photoheight/jobs/${this.jobId}/map_print_configs/${newKey}`)
          .set(newConfig)
          .then(() => {
            // Once the update is finished, set the currentMapPrintConfigsKey to
            // the new key created so that the data will load
            this.currentMapPrintConfigsKey = newKey;
          });
      }
    } else {
      // If no name is given, then show a warning
      this.showSaveWarning();
    }
  }
  async deleteMapPrintConfig() {
    if (this.currentMapPrintConfigsKey) {
      this.turnOffMapLayers();
      await FirebaseWorker.ref(`photoheight/jobs/${this.jobId}/map_print_config_layers/${this.currentMapPrintConfigsKey}`).remove();
      await FirebaseWorker.ref(`photoheight/jobs/${this.jobId}/map_print_configs/${this.currentMapPrintConfigsKey}`).remove();
      this.currentMapPrintConfigsKey = null;
    }
  }
  otherAttributesChanged() {
    if (this.otherAttributes) {
      let nodeAttributesList = Object.keys(this.otherAttributes).filter((x) =>
        (this.otherAttributes[x]?.attribute_types || []).includes('node')
      );
      let connectionAttributesList = Object.keys(this.otherAttributes).filter((x) =>
        (this.otherAttributes[x]?.attribute_types || []).includes('connection')
      );
      if (nodeAttributesList.indexOf('latitude') == -1) nodeAttributesList.push('latitude');
      if (nodeAttributesList.indexOf('longitude') == -1) nodeAttributesList.push('longitude');
      if (nodeAttributesList.indexOf('make_ready_notes') == -1) nodeAttributesList.push('make_ready_notes');
      if (connectionAttributesList.indexOf('span_distances') == -1) connectionAttributesList.push('span_distances');
      this.nodeSeedAttributeAnnotations = nodeAttributesList;
      this.connSeedAttributeAnnotations = connectionAttributesList;

      // Set the job attributes
      let jobAttributesList = SquashNulls(this.getGenericMapPrintConfig(), 'model', 'titleBlock', 'fields') || [];
      jobAttributesList.forEach((x) => (x.label = CamelCase(x.attribute)));
      for (let attribute in this.otherAttributes) {
        let node = this.otherAttributes[attribute].attribute_types?.includes('node');
        let job = this.otherAttributes[attribute].attribute_types?.includes('job');
        if (node || job) {
          jobAttributesList.push({ attribute, node, job, label: CamelCase(attribute) });
        }
      }
      this.titleBlockFields = jobAttributesList;
    }
  }

  mapPrintAnnotationLogicChanged() {
    if (this.mapPrintAnnotationLogic)
      this.seedComputedAnnotations = Object.entries(this.mapPrintAnnotationLogic).map(([key, val]) => {
        return { key, name: val.name };
      });
  }

  nodeAttributesSortChanged(e) {
    let paths = ['defaultNodeAttributes', 'fields'];
    paths.forEach((path) => {
      let removed = this.splice(`currentMapPrintConfig.model.annotations.${path}`, e.detail.prevIndex, 1);
      this.splice(`currentMapPrintConfig.model.annotations.${path}`, e.detail.index, 0, removed[0]);
    });
  }
  titleBlockFieldsSortChanged(e) {
    let removed = this.splice('currentMapPrintConfig.model.titleBlock.fields', e.detail.prevIndex, 1);
    this.splice('currentMapPrintConfig.model.titleBlock.fields', e.detail.index, 0, removed[0]);
  }
  legendFieldsSortChanged(e) {
    let removed = this.splice('currentMapPrintConfig.model.legend.fields', e.detail.prevIndex, 1);
    this.splice('currentMapPrintConfig.model.legend.fields', e.detail.index, 0, removed[0]);
  }
  openDeleteMapPrintDialog() {
    this.closeAllDialogs();
    this.$.deleteMapPrintDialog.open();
  }
  openMapPrintConfigSettingsDialog() {
    this.closeAllDialogs();
    this.$.mapPrintConfigSettingsDialog.open();
  }
  openSaveAsDialog() {
    this.closeAllDialogs();
    // Clear any existing name warnings
    this.clearSaveWarning();
    // Clear any dialog data before opening
    this.newTemplateName = null;
    this.mapPrintTemplatesKeyToSaveAs = null;
    this.$.saveAsDialog.open();
  }
  openCustomScaleDialog() {
    this.$.customScaleDialog.open();
  }

  filterSvgFiles() {
    if (this.files) return Object.values(this.files)?.filter((file) => file.type == 'image/svg+xml');
    else return [];
  }

  setCustomScale() {
    this.newFeetPerInch = this.customScale + "'";
    this.set(`currentMapPrintConfig.model.customFeetPerInch`, this.newFeetPerInch);
    this.$.changeFeetPerInchDialog.open();
  }
  calcPrintScales() {
    if (this.currentMapPrintConfig && this.currentMapPrintConfig.model) {
      let scales = [`50'`, `100'`, `200'`];
      if (this.currentMapPrintConfig.model.customFeetPerInch) {
        scales.push(this.currentMapPrintConfig.model.customFeetPerInch);
        this.set('scales', scales);
      } else this.set('scales', scales);
    }
  }
  saveConfigAs() {
    // Clear any existing name warnings
    this.clearSaveWarning();
    // Check that there is a current map print config to save
    if (this.currentMapPrintConfig && this.jobId && this.jobCreator) {
      // Check if the user is trying to save as a new template but didn't give a name
      if (this.saveAsType == 'new' && !this.newTemplateName) {
        // If there is no name set, then show a warning to the user
        this.showSaveWarning();
      } else {
        // Create a new key to use for saving by default
        let keyToSave = FirebaseWorker.ref(`/`).push().key;
        // Default the name to Untitled
        let nameToUse = 'Untitled';
        if (this.saveAsType == 'new') {
          // Use the name given by the user
          nameToUse = this.newTemplateName;
        }
        // Check if we are saving over an existing template and have a key for it
        else if (this.saveAsType == 'existing' && this.mapPrintTemplatesKeyToSaveAs) {
          // Update the key to save to the selected template's key
          keyToSave = this.mapPrintTemplatesKeyToSaveAs;
          nameToUse = SquashNulls(this.companyMapPrintTemplates, this.mapPrintTemplatesKeyToSaveAs, 'name') || nameToUse;
        }
        // Make a copy of the config's model (so we leave out the name)
        let currentMapPrintConfigCopy = JSON.parse(JSON.stringify(this.currentMapPrintConfig));
        currentMapPrintConfigCopy.name = nameToUse;
        // Save to Firebase
        FirebaseWorker.ref(`/photoheight/company_space/${this.jobCreator}/models/export_models/map_print_templates/${keyToSave}`).set(
          currentMapPrintConfigCopy
        );
        this.closeAllDialogs();
      }
    }
  }
  clearSaveWarning() {
    dom(this.shadowRoot)
      .querySelectorAll('.newNameInput')
      .forEach((input) => {
        input.invalid = false;
      });
  }
  showSaveWarning() {
    dom(this.shadowRoot)
      .querySelectorAll('.newNameInput')
      .forEach((input) => {
        input.errorMessage = 'Please enter a name';
        input.invalid = true;
      });
  }
  currentMapPrintConfigsKeyChanged(currentMapPrintConfigsKey) {
    if (!currentMapPrintConfigsKey) {
      this.closeAllDialogs();
    } else {
      setTimeout(() => {
        this.pagesLayerSelectable = true;
        this.pagesLayerOn = true;
        this.annotationsLayerSelectable = true;
        this.annotationsLayerOn = true;
        this.overwriteAnnotationPosition = false;
        this.overwriteAnnotationStyle = false;
        this.overwriteAnnotationText = true;
        this.__dataHost.set('currentMapPrintConfigsKey', currentMapPrintConfigsKey);
      });
    }
  }
  async downloadFullPDF(e) {
    let button = e.currentTarget;
    // Wait a second so any loading UI changes can happen before main-thread is blocked by this operation.
    await new Promise((x) => setTimeout(x, 1000));

    if (this.currentMapPrintConfig && this.currentMapPrintConfigsKey) {
      button.loading = true;

      let documentSize = [
        this.currentMapPrintConfig.model.document.width * this.currentMapPrintConfig.model.document.resolution,
        this.currentMapPrintConfig.model.document.height * this.currentMapPrintConfig.model.document.resolution
      ];
      // Swap sizes if landscape
      if (this.currentMapPrintConfig.model.paperSpace.orientation != 'portrait') {
        documentSize.reverse();
      }
      const { PDFDocument } = await import('../../../js/open-source/pdfkit.standalone.esm.js');
      let doc = new PDFDocument({
        layout: this.currentMapPrintConfig.model.paperSpace.orientation,
        size: documentSize,
        autoFirstPage: false
      });
      let stream = doc.pipe(blobStream());
      stream.on('finish', () => {
        // Get the blob data and add it to
        let pdfBlob = stream.toBlob('application/pdf');
        let url = URL.createObjectURL(pdfBlob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `${this.jobName} Map Print.pdf`;
        document.body.appendChild(a);
        a.click();
        button.loading = false;
      });

      let formPages = await this.printGenerator.generateForm({
        type: 'map',
        jobId: this.jobId,
        mapPrintConfig: this.currentMapPrintConfig,
        mapPrintConfigKey: this.currentMapPrintConfigsKey
      });

      formPages.forEach((form) => {
        // Create an image callback function that will fetch the array
        // buffer for the image URL from the form object's lookup
        let imageCallback = (url) => {
          return form.svgElement.imageArrayBufferLookup[url];
        };
        doc.addPage();
        SVGtoPDF(doc, form.svgElement.svg.node, 0, 0, { imageCallback, preserveAspectRatio: 'xMinYMin meet' });
      });

      doc.end();
    }
  }
  async tappedShowPreview() {
    this.$.previewDialog.open();
    this.updatePreview();
  }
  async updatePreview() {
    this.previewError = null;
    // Check if we have a config and key
    if (this.currentMapPrintConfig && this.currentMapPrintConfigsKey) {
      this.$.contentArea.innerHTML = '';
      this.loadingPreview = true;
      // Get the SVG generated for a single page
      let formPages = await this.printGenerator.generateForm({
        type: 'map',
        jobId: this.jobId,
        mapPrintConfig: this.currentMapPrintConfig,
        mapPrintConfigKey: this.currentMapPrintConfigsKey,
        printOnePage: true
      });

      this.loadingPreview = false;

      if (formPages.error) {
        this.previewError = `Could not generate preview. ${formPages.error}`;
      } else {
        this.$.contentArea.appendChild(formPages[0].svgElement.svg.node);
      }
    } else {
      this.previewError = 'No Map Print Config is selected.';
    }
  }
  calcShowToolbar(viewMode) {
    let show = viewMode == 'print';
    this.pagesLayerOn = show && this.currentMapPrintConfigsKey;
    this.annotationsLayerOn = show && this.currentMapPrintConfigsKey;
    return show;
  }
  close() {
    this.viewMode = null;
  }
  insertMapPage() {
    this.temporarilyTurnOffSelectable();
    this.pagesLayerOn = true;
    this.insertItemType = 'pages';
    this.__dataHost.actionDialogModel = { icon: 'image:crop-din', color: 'rgba(0, 62, 81, 0.9)' };
    this.__dataHost.activeCommand = '_addMapPrintItem';
    this.__dataHost.$.katapultMap.openActionDialog({ title: 'Insert Map Page', icon: true });
  }
  insertAnnotation() {
    this.temporarilyTurnOffSelectable();
    this.annotationsLayerOn = true;
    this.insertItemType = 'annotations';
    this.__dataHost.actionDialogModel = { icon: 'maps:rate-review', color: 'var(--primary-text-color-faded)' };
    this.__dataHost.activeCommand = '_addMapPrintItem';
    this.__dataHost.$.katapultMap.openActionDialog({ title: 'Insert Annotation', icon: true });
  }
  temporarilyTurnOffSelectable() {
    this.tempSettingPagesSelectable = this.pagesLayerOn ? this.pagesLayerSelectable : true;
    this.tempSettingAnnotationsSelectable = this.annotationsLayerOn ? this.annotationsLayerSelectable : true;
    this.toggleSelectable('pages', false);
    this.toggleSelectable('annotations', false);
  }
  restoreSelectableSettings() {
    this.toggleSelectable('pages', this.tempSettingPagesSelectable);
    this.toggleSelectable('annotations', this.tempSettingAnnotationsSelectable);
  }
  togglePagesLayer() {
    if (!this.pagesLayerOn) this.pagesLayerSelectable = false;
    this.__dataHost.set(
      `multiJobIds.pages`,
      this.pagesLayerOn
        ? {
            url: `photoheight/jobs/${this.jobId}/map_print_config_layers/${this.currentMapPrintConfigsKey}/pages`,
            clickable: this.pagesLayerSelectable,
            zIndex: 1
          }
        : null
    );
  }
  toggleAnnotationsLayer() {
    if (!this.annotationsLayerOn) this.annotationsLayerSelectable = false;
    this.__dataHost.set(
      `multiJobIds.annotations`,
      this.annotationsLayerOn
        ? {
            url: `photoheight/jobs/${this.jobId}/map_print_config_layers/${this.currentMapPrintConfigsKey}/annotations`,
            clickable: this.annotationsLayerSelectable,
            zIndex: 2
          }
        : null
    );
  }
  toggleSelectable(e, setting) {
    let type = e.currentTarget ? e.currentTarget.getAttribute('name') : e;
    if (this[type + 'LayerOn']) {
      this[type + 'LayerSelectable'] = typeof setting === 'boolean' ? setting : !this[type + 'LayerSelectable'];
      this.__dataHost.set(`multiJobIds.${type}.clickable`, this[type + 'LayerSelectable']);
    }
  }
  selectLayer(e) {
    if (e.currentTarget.checked) this.toggleSelectable(e, true);
  }
  turnOffMapLayers() {
    this.annotationsLayerOn = false;
    this.pagesLayerOn = false;
  }
  getAnnotationStyle(origin, text, options = {}) {
    let size = this.getAnnotationSize(origin, text);

    // Don't use a shape if they chose rectangle so that we use the existing logic
    // todo this should be fixed later to just use rectangles as SVGs like everything else
    let backgroundShape =
      this.currentMapPrintConfig.model.annotations.backgroundShape == 'map-prints:rounded-rectangle'
        ? null
        : this.currentMapPrintConfig.model.annotations.backgroundShape ?? null;
    let leader = null;

    if (this.currentMapPrintConfig.model.annotations.leader && !options.noLeader) {
      leader = {
        color: this.currentMapPrintConfig.model.annotations.leaderColor,
        edge: 'top',
        endPoint: this.currentMapPrintConfig.model.annotations.arrowEndpoint ? 'arrow' : null,
        percent: 0
      };

      if (this.katapultMaps.nodes?.[options.nodeId]) {
        let offset = (5 * parseInt(this.currentMapPrintConfig.model.feetPerInch)) / 100;
        let originLL = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(...origin), offset + 10, 135);
        origin = [originLL.lat(), originLL.lng()];

        let nodeLL = new google.maps.LatLng(
          this.katapultMaps.nodes[options.nodeId].latitude,
          this.katapultMaps.nodes[options.nodeId].longitude
        );
        let leaderPoint = google.maps.geometry.spherical.computeOffset(nodeLL, offset, 135);
        leader.points = [origin, [leaderPoint.lat(), leaderPoint.lng()]];
        leader.snapToNode = true;
      } else {
        let leaderPoint = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(origin[0], origin[1]), 10, 315);
        leader.points = [origin, [leaderPoint.lat(), leaderPoint.lng()]];
      }
    }

    options = options || {};
    return Object.assign(
      {
        anchor: 'TOP_LEFT',
        fill: this.currentMapPrintConfig.model.annotations.boxColor,
        fontSize: this.currentMapPrintConfig.model.annotations.textSize,
        height: size.height,
        rotation: 0,
        origin,
        stroke: this.currentMapPrintConfig.model.annotations.outlineColor,
        strokeWidth: this.currentMapPrintConfig.model.annotations.outlineWidth,
        feetPerInch: parseInt(this.currentMapPrintConfig.model.feetPerInch),
        t: 'a',
        backgroundShape,
        text: text || '',
        fontColor: this.currentMapPrintConfig.model.annotations.textColor,
        width: size.width,
        borderRadius: 5,
        leader
      },
      options
    );
  }
  getAnnotationSize(anchorPoint, text, fontSize, feetPerInch) {
    // Get a Google Maps LatLng object for the anchor point of the text
    anchorPoint = new google.maps.LatLng({ lat: anchorPoint[0], lng: anchorPoint[1] });

    fontSize = fontSize || this.currentMapPrintConfig.model.annotations.textSize;
    fontSize = parseFloat(fontSize);
    let fontFeet = (fontSize * parseInt(feetPerInch || this.currentMapPrintConfig.model.feetPerInch)) / 72; // fontSize is in pt. 72pt = 1" and scale is value of data.feetPerInch so fontFeet = (fontPt * 1"/72pt * data.feetPerInch)
    let fontBottomLL = google.maps.geometry.spherical.computeOffset(anchorPoint, fontFeet * 0.3048, 180);
    loadRenderMap.setupDropOverlay();
    let fontTop = loadRenderMap.dropOverlay.fromLatLngToPixel(anchorPoint);
    let fontBottom = loadRenderMap.dropOverlay.fromLatLngToPixel(fontBottomLL);
    fontSize = Math.abs(fontTop.y - fontBottom.y) + 'px';

    let padding = 0;
    let lines = (text || '').split('\n');
    let maxWidth = 0;
    let maxHeight = 0;
    this.$.ruler.style.fontSize = fontSize;
    lines.forEach((line) => {
      // Allow empty lines to keep their height
      if (line == '') line = '&nbsp;';
      this.$.ruler.textContent = line;
      let width = this.$.ruler.offsetWidth;
      // Set padding to be 50% of the text height (took this out because it's too much and text needs to be centered)
      // padding = this.$.ruler.offsetHeight * 0.5;
      maxHeight += this.$.ruler.offsetHeight;
      if (width > maxWidth) {
        maxWidth = width;
      }
    });
    if (maxWidth == 0) maxWidth = 80;
    if (maxHeight == 0) maxHeight = 40;

    // Add padding
    maxWidth += padding * 2;
    maxHeight += padding * 2;

    // Conver the anchor point to a pixel coordinate using the overlay
    let anchorPixelPoint = loadRenderMap.dropOverlay.fromLatLngToPixel(anchorPoint);
    // Get a new pixel point by adding on the size in pixels to the anchorPixelPoint
    let newPixelPoint = {
      x: anchorPixelPoint.x + maxWidth,
      y: anchorPixelPoint.y + maxHeight
    };
    // Convert the new pixel point back to lat long
    let newLatLong = loadRenderMap.dropOverlay.fromPixelToLatLng(newPixelPoint.x, newPixelPoint.y);
    // Get the width and height distance in meters between the two coordinates
    let width = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng({ lat: anchorPoint.lat(), lng: anchorPoint.lng() }),
      new google.maps.LatLng({ lat: anchorPoint.lat(), lng: newLatLong.lng() })
    );
    let height = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng({ lat: anchorPoint.lat(), lng: anchorPoint.lng() }),
      new google.maps.LatLng({ lat: newLatLong.lat(), lng: anchorPoint.lng() })
    );
    // Convert width and height to feet
    width /= 0.3048;
    height /= 0.3048;

    return { width, height };
  }

  feetPerInchWillChange(e, detail) {
    // Reset value
    this.newFeetPerInch = null;
    // Check that the value is changing to something different
    if (detail && detail.value && detail.previousValue && detail.value != detail.previousValue) {
      // Stop change from happening
      if (e.currentTarget.tagName == 'KATAPULT-DROP-DOWN') {
        e.currentTarget.preventSelection = true;
      }
      // Set the newFeetPerInch for later
      this.newFeetPerInch = detail.value;
      // Open the prompt
      this.$.changeFeetPerInchDialog.open();
    }
  }
  async changeFeetPerInch(e) {
    // Set the button to be loading
    let button = e.currentTarget;
    button.loading = true;

    // Wait a second so any loading UI changes can happen before main-thread is blocked by this operation.
    await new Promise((x) => setTimeout(x, 1000));

    // Check that we have all needed values
    if (this.newFeetPerInch && this.currentMapPrintConfig && this.currentMapPrintConfigsKey) {
      let authToken = await firebase.auth().currentUser.getIdToken();
      if (authToken) {
        // Set the new value for the feet per inch
        this.set(`currentMapPrintConfig.model.feetPerInch`, this.newFeetPerInch);
        // Get the annotations
        let annotations = await FirebaseWorker.ref(
          `photoheight/jobs/${this.jobId}/map_print_config_layers/${this.currentMapPrintConfigsKey}/annotations`
        )
          .once('value')
          .then((s) => s.val());
        // Adjust all pages and annotations to be based on the new feet per inch
        let update = {};
        for (let pageKey in this.currentMapPrintPages) {
          update[`map_print_config_layers/${this.currentMapPrintConfigsKey}/pages/${pageKey}/width`] =
            this.currentMapPrintConfig.model.map.width * parseInt(this.newFeetPerInch);
          update[`map_print_config_layers/${this.currentMapPrintConfigsKey}/pages/${pageKey}/height`] =
            this.currentMapPrintConfig.model.map.height * parseInt(this.newFeetPerInch);
          update[`map_print_config_layers/${this.currentMapPrintConfigsKey}/pages/${pageKey}/feetPerInch`] = parseInt(this.newFeetPerInch);
        }
        for (let annotationKey in annotations) {
          let newSize = this.getAnnotationSize(annotations[annotationKey].origin, annotations[annotationKey].text);
          update[`map_print_config_layers/${this.currentMapPrintConfigsKey}/annotations/${annotationKey}/width`] = newSize.width;
          update[`map_print_config_layers/${this.currentMapPrintConfigsKey}/annotations/${annotationKey}/height`] = newSize.height;
          update[`map_print_config_layers/${this.currentMapPrintConfigsKey}/annotations/${annotationKey}/feetPerInch`] = parseInt(
            this.newFeetPerInch
          );
        }
        // Update the job
        await FirebaseWorker.ref(`photoheight/jobs/${this.jobId}`).update(update);
      }
    }

    button.loading = false;
    this.closeAllDialogs();
  }

  async addMapPrintItem(e) {
    let update = {};
    let model = {};
    if (this.insertItemType == 'pages') {
      //Get Last PageNumber
      let lastPageNumber = 0;
      for (var pageId in this.currentMapPrintPages) {
        let pageNumber = parseInt(this.currentMapPrintPages[pageId].text);
        if (pageNumber > lastPageNumber) {
          lastPageNumber = pageNumber;
        }
      }
      model = {
        anchor: 'TOP_LEFT',
        fill: 'rgba(0, 62, 81, 0.6)',
        fontSize: 248,
        // Height should be the height of the map in inches times the chosen feet per inch
        height: this.currentMapPrintConfig.model.map.height * parseInt(this.currentMapPrintConfig.model.feetPerInch),
        rotation: 0,
        stroke: 'rgba(0, 62, 81, 0.9)',
        strokeWidth: 1,
        origin: [e.detail.latLng.lat(), e.detail.latLng.lng()],
        feetPerInch: parseInt(this.currentMapPrintConfig.model.feetPerInch),
        t: 'a',
        fontColor: 'rgba(255, 255, 255, 0.8)',
        // Width should be the width of the map in inches times the chosen feet per inch
        width: this.currentMapPrintConfig.model.map.width * parseInt(this.currentMapPrintConfig.model.feetPerInch),
        text: lastPageNumber + 1 + ''
      };
    } else if (this.insertItemType == 'annotations') {
      model = this.getAnnotationStyle([e.detail.latLng.lat(), e.detail.latLng.lng()]);
    }
    let key = this.createMapPrintItem(model, update);
    await FirebaseWorker.ref(
      `photoheight/jobs/${this.jobId}/map_print_config_layers/${this.currentMapPrintConfigsKey}/${this.insertItemType}`
    ).update(update);
    if (this.insertItemType == 'annotations') {
      this.pageElement.openAnnotationEditor({ detail: { key, geoData: model, jobId: 'annotations', latLng: e.detail.latLng } });
      this.pageElement.cancelPromptAction();
    }
  }
  createMapPrintItem(data, update, key, existingAnnotations) {
    key = key || FirebaseWorker.ref().push().key;
    let corners = loadRenderMap.getTextOverlayCorners(data);
    corners.forEach((corner, i) => {
      let l = [corner.lat(), corner.lng()];
      let pointKey = key + '~' + (i + 1);
      let cornerData = JSON.parse(JSON.stringify(data));
      if (existingAnnotations?.[pointKey]) {
        if (this.overwriteAnnotationPosition) {
          var geohash = GeofireTools._encodeGeohash(l, 10);
          update[pointKey + '/.priority'] = geohash;
          update[pointKey + '/g'] = geohash;
          update[pointKey + '/l'] = l;
          update[pointKey + '/origin'] = data.origin;
          existingAnnotations[pointKey].origin = data.origin;
          existingAnnotations[pointKey].l = l;
          if (data.leader) {
            update[pointKey + '/leader/points'] = data.leader.points;
            update[pointKey + '/leader/edge'] = data.leader.edge;
            update[pointKey + '/leader/percent'] = data.leader.percent;
          } else {
            update[pointKey + '/leader'] = null;
          }
        }
        if (this.overwriteAnnotationStyle) {
          for (let key in data) {
            if (key == 'leader' && existingAnnotations[pointKey].leader) {
              update[pointKey + '/leader/color'] = data.leader?.color ?? null;
              update[pointKey + '/leader/endPoint'] = data.leader?.endPoint ?? null;
            }
            if (!['height', 'width', 'text', 'origin', 'leader'].includes(key)) {
              update[pointKey + '/' + key] = data[key];
            }
          }
        }
        if (this.overwriteAnnotationText) {
          update[pointKey + '/height'] = data.height;
          update[pointKey + '/width'] = data.width;
          update[pointKey + '/text'] = data.text;
        }
      } else {
        GeofireTools.update(pointKey, l, cornerData, 10, update);
      }
    });
    return key;
  }
  cancelInsertMapPrintItem() {
    this.restoreSelectableSettings();
  }
  addComputedAttribute(e) {
    let logicKey = e.detail.value;
    if (logicKey != '') {
      // fields
      if (this.currentMapPrintConfig.model.annotations.fields == null)
        this.set('currentMapPrintConfig.model.annotations.fields', [
          { attribute: 'Computed', logic: logicKey, label: e.detail.selectedItem?.name }
        ]);
      else
        this.push('currentMapPrintConfig.model.annotations.fields', {
          attribute: 'Computed',
          logic: logicKey,
          label: e.detail?.selectedItem?.name
        });
      // defaultNodeAttributes
      if (this.currentMapPrintConfig.model.annotations.defaultNodeAttributes == null)
        this.set('currentMapPrintConfig.model.annotations.defaultNodeAttributes', ['computed']);
      else this.push('currentMapPrintConfig.model.annotations.defaultNodeAttributes', 'computed');

      e.currentTarget.clear();
    }
  }
  addNodeAttribute(e) {
    let attribute = e.detail.value;
    if (attribute != '') {
      if (this.currentMapPrintConfig.model.annotations.fields == null) this.set('currentMapPrintConfig.model.annotations.fields', []);
      if (this.currentMapPrintConfig.model.annotations.defaultNodeAttributes == null)
        this.set('currentMapPrintConfig.model.annotations.defaultNodeAttributes', []);
      this.push('currentMapPrintConfig.model.annotations.defaultNodeAttributes', attribute);
      this.push('currentMapPrintConfig.model.annotations.fields', { attribute, label: CamelCase(attribute) });

      e.currentTarget.clear();
    }
  }
  addTitleBlockField(e) {
    let attribute = e.detail.value;
    if (attribute != '') {
      let type = null;
      if (e.detail.selectedItem.node) {
        if (e.detail.selectedItem.job) {
          type = 'jobAttribute';
        } else {
          type = 'firstNodeValue';
        }
      }
      let item = { attribute, label: attribute == '_text' ? '' : CamelCase(attribute), type };
      if (!this.get('currentMapPrintConfig.model.titleBlock.fields')) {
        this.set('currentMapPrintConfig.model.titleBlock.fields', [item]);
      } else {
        this.push('currentMapPrintConfig.model.titleBlock.fields', item);
      }
      e.currentTarget.clear();
    }
  }
  removeNodeAttribute(e) {
    this.splice('currentMapPrintConfig.model.annotations.defaultNodeAttributes', e.model.index, 1);
    this.splice('currentMapPrintConfig.model.annotations.fields', e.model.index, 1);
  }
  removeTitleBlockField(e) {
    this.splice('currentMapPrintConfig.model.titleBlock.fields', e.model.index, 1);
  }
  camelCase(text) {
    return CamelCase(text);
  }
  async seedAnnotations() {
    this.preppingForSeedAnnotations = true;
    let existingAnnotations = await FirebaseWorker.ref(
      `photoheight/jobs/${this.jobId}/map_print_config_layers/${this.currentMapPrintConfigsKey}/annotations`
    )
      .once('value')
      .then((s) => s.val());
    // Check for any other data we need to load
    let fields = this.currentMapPrintConfig?.model?.annotations?.fields;
    let photos = {};
    if (fields?.some((field) => ['make_ready_notes'].includes(field.attribute))) {
      photos = await GetJobData(this.jobId, 'photos').then((data) => data.photos || {});
    }
    this.preppingForSeedAnnotations = false;
    if (
      !existingAnnotations ||
      (await this.confirm({ title: 'Seed Annotations', body: 'What would you like to overwrite?', type: 'seedAnnotations' }))
    ) {
      let update = {};
      let includeAttributeLabels = this.get('currentMapPrintConfig.model.annotations.includeAttributeLabels');
      const getLabel = (field, _label) => {
        let label = '';
        if (includeAttributeLabels) {
          label = `${field.label ?? _label ?? CamelCase(field.attribute)}`;
          if (label) label += ': ';
        }
        return label;
      };
      let companyLookup = GetAttributeLookup(this.otherAttributes.company || {});
      let jobData = await FirebaseWorker.ref(`photoheight/jobs/${this.jobId}`)
        .once('value')
        .then((s) => s.val());

      for (var nodeId in this.nodes) {
        let text = '';
        let geoStyle = GeofireTools._getItemGeoStyle('nodes', this.nodes[nodeId], this.jobStyles);
        if (!SquashNulls(this.pageElement.hiddenLegendItems, geoStyle.u)) {
          if (fields) {
            for (let i = 0; i < fields.length; i++) {
              let field = fields[i];
              // if field has a logic attribute, we know it's computed annotation.  If it doesn't, it's an attribute annotation
              if (field.logic) {
                // maintain the filter checking here
                if (!field.filter || KLogic.compute(field.filter, { node: this.nodes[nodeId], attribute: val })) {
                  let annotationLabel = field.label || 'No label';
                  let logicKey = field.logic;
                  let annotationKLogicExpressions = await FirebaseWorker.ref(
                    `photoheight/company_space/${this.jobCreator}/models/map_print_annotation_logic/${logicKey}/logic`
                  )
                    .once('value')
                    .then((s) => s.val());
                  if (annotationKLogicExpressions) {
                    let kLogicResults = [];
                    for (let annotationKLogicExpression of annotationKLogicExpressions) {
                      // create the dataset to make available to the KLogic statements
                      let kLogicStandardDataset = GetKLogicStandardDataset('nodes', jobData, this.otherAttributes, { nodeId });
                      // use the dataset and the expression to compute the resulting value
                      let result = KLogic.compute(annotationKLogicExpression, kLogicStandardDataset);
                      if (result != null && result != '') {
                        kLogicResults.push(result);
                      }
                    }
                    // set the resulting kLogic value to the text value
                    // split the text by new line characters so we can print each line on its own line
                    if (kLogicResults.length != 0 && i != 0) text += '\n';
                    let finalResults = kLogicResults.join(', ');
                    let resultLines = finalResults.split('\\n');
                    resultLines.forEach((result, index) => {
                      if (index != 0) text += '\n';
                      text += result;
                    });
                  } else text += `\n${annotationLabel}: Couldn't find logic for this computed annotation`;
                }
              }
              // attribute annotation execution code
              else {
                if (field.attribute == 'latitude') {
                  if (text != '') text += '\n';
                  text += `${getLabel(field)}${this.nodes[nodeId].latitude}`;
                } else if (field.attribute == 'longitude') {
                  if (text != '') text += '\n';
                  text += `${getLabel(field)}${this.nodes[nodeId].longitude}`;
                } else if (field.attribute == 'make_ready_notes') {
                  const photo = photos[GetMainPhoto(this.nodes[nodeId].photos)];
                  if (photo != null) {
                    let makeReadyOptions = null;
                    if (this.userGroup == 'orbital_engineering_inc') {
                      makeReadyOptions = {
                        includeAllProposed: true,
                        skipPreConstruction: true,
                        showSpanBearings: false,
                        showSpanToPoleTag: true,
                        showSpanProposedHeight: true,
                        showSpanMoveAmount: true,
                        showSpanMovementDescriptor: false,
                        showProposedHeight: true,
                        includeHeightInMarkerName: false,
                        markerLabel: {
                          includeCompanyForCommCables: true,
                          includeCompanyForOtherTypes: true,
                          useCompanyShortnameForCommCables: false,
                          useCompanyShortnameForPowerCables: false,
                          useCompanyShortnameForOtherTypes: false,
                          includeSpecs: ['drip_loop_spec']
                        }
                      };
                    }
                    let value = await calcMakeReadyNotes({
                      nodes: this.nodes,
                      photos,
                      traces: this.traces,
                      useMetricUnits: this.useMetricUnits,
                      photo,
                      nodeId,
                      otherAttributes: this.otherAttributes,
                      makeReadyOptions
                    });
                    if (value) {
                      if (text != '') text += '\n';
                      text += `${getLabel(field)}${value}`;
                    }
                  }
                } else {
                  let values = SquashNulls(this.nodes[nodeId].attributes, field.attribute);
                  for (let itemKey in values) {
                    let val = values[itemKey];
                    if (!field.filter || KLogic.compute(field.filter, { node: this.nodes[nodeId], attribute: val })) {
                      if (field.attribute === 'pole_tag') {
                        val =
                          (field.hide_pole_tag_company ? '' : SquashNulls(values[itemKey], 'company') + ' ') +
                          SquashNulls(values[itemKey], 'tagtext');
                      }
                      if (val != '') {
                        if (text != '') text += '\n';
                        text += `${getLabel(field)}${val}`;
                      }
                    }
                  }
                }
              }
            }
            if (text != '') {
              this.createMapPrintItem(
                this.getAnnotationStyle([this.nodes[nodeId].latitude, this.nodes[nodeId].longitude], text, { nodeId }),
                update,
                nodeId,
                existingAnnotations
              );
            } else {
              update[nodeId + '~1'] = null;
              update[nodeId + '~2'] = null;
              update[nodeId + '~3'] = null;
              update[nodeId + '~4'] = null;
            }
          }
        }
      }
      if (!this.enabledFeatures?.display_connection_seeding_for_map_prints) {
        for (const connId in this.connections) {
          let geoStyle = GeofireTools._getItemGeoStyle('connections', this.connections[connId], this.jobStyles);
          let n1 = SquashNulls(this.nodes, this.connections[connId].node_id_1);
          let n2 = SquashNulls(this.nodes, this.connections[connId].node_id_2);
          let text = '';
          if (n1 && n2 && !SquashNulls(this.pageElement.hiddenLegendItems, geoStyle.u)) {
            if (this.currentMapPrintConfig.model.annotations.includeSpanDistance) {
              let p1 = new google.maps.LatLng(n1.latitude, n1.longitude);
              let p2 = new google.maps.LatLng(n2.latitude, n2.longitude);
              let length = google.maps.geometry.spherical.computeDistanceBetween(p1, p2);
              let bearing = google.maps.geometry.spherical.computeHeading(p1, p2);
              let rotation = bearing + (bearing > 0 ? -90 : 90);
              if (this.useMetricUnits) {
                if (this.modelConfig.round_distances_up) text = Math.ceil(length) + 'm';
                else text = Round(length, 1) + 'm';
              } else {
                if (this.modelConfig.round_distances_up) text = Math.ceil(length / 0.3048) + "'";
                else text = Round(length / 0.3048, 1) + "'";
              }
              let midpoint = loadRenderMap.getMidpointLatLng(p1, p2);
              this.createMapPrintItem(
                this.getAnnotationStyle([midpoint.lat(), midpoint.lng()], text, { rotation, anchor: 'TOP_CENTER', noLeader: true }),
                update,
                connId,
                existingAnnotations
              );
            } else {
              update[connId + '~1'] = null;
              update[connId + '~2'] = null;
              update[connId + '~3'] = null;
              update[connId + '~4'] = null;
            }
          }
        }
      }
      await FirebaseWorker.ref(
        `photoheight/jobs/${this.jobId}/map_print_config_layers/${this.currentMapPrintConfigsKey}/annotations`
      ).update(update);
      this.annotationsLayerOn = true;
      this.toggleSelectable('annotations', true);
    }
  }
  async seedConnectionAnnotations() {
    this.preppingForSeedConnAnnotations = true;
    let existingAnnotations = await FirebaseWorker.ref(
      `photoheight/jobs/${this.jobId}/map_print_config_layers/${this.currentMapPrintConfigsKey}/annotations`
    )
      .once('value')
      .then((s) => s.val());
    // Check for any other data we need to load
    let connFields = this.currentMapPrintConfig?.model?.annotations?.connectionFields;
    // Not including photos because connections don't have photos
    this.preppingForSeedConnAnnotations = false;
    if (
      !existingAnnotations ||
      (await this.confirm({ title: 'Seed Annotations', body: 'What would you like to overwrite?', type: 'seedAnnotations' }))
    ) {
      let update = {};
      let includeConnAttributeLabels = this.get('currentMapPrintConfig.model.annotations.includeAttributeLabels');
      const getLabel = (connField, _label) => {
        let label = '';
        if (includeConnAttributeLabels) {
          label = `${connField.label ?? _label ?? CamelCase(connField.attribute)}`;
          if (label) label += ': ';
        }
        return label;
      };
      let jobData = await FirebaseWorker.ref(`photoheight/jobs/${this.jobId}`)
        .once('value')
        .then((s) => s.val());
      for (var connId in this.connections) {
        let geoStyle = GeofireTools._getItemGeoStyle('connections', this.connections[connId], this.jobStyles);
        let n1 = SquashNulls(this.nodes, this.connections[connId].node_id_1);
        let n2 = SquashNulls(this.nodes, this.connections[connId].node_id_2);
        let text = '';
        let p1 = new google.maps.LatLng(n1.latitude, n1.longitude);
        let p2 = new google.maps.LatLng(n2.latitude, n2.longitude);
        let length = google.maps.geometry.spherical.computeDistanceBetween(p1, p2);
        let bearing = google.maps.geometry.spherical.computeHeading(p1, p2);
        let rotation = bearing + (bearing > 0 ? -90 : 90);
        if (n1 && n2 && !SquashNulls(this.pageElement.hiddenLegendItems, geoStyle.u)) {
          if (this.currentMapPrintConfig.model.annotations.includeSpanDistance) {
            if (this.useMetricUnits) {
              if (this.modelConfig.round_distances_up) text = Math.ceil(length) + 'm';
              else text = Round(length, 1) + 'm';
            } else {
              if (this.modelConfig.round_distances_up) text = Math.ceil(length / 0.3048) + "'";
              else text = Round(length / 0.3048, 1) + "'";
            }
          }
        }
        let midpoint = loadRenderMap.getMidpointLatLng(p1, p2);
        if (text != '' && midpoint) {
          this.createMapPrintItem(
            this.getAnnotationStyle([midpoint.lat(), midpoint.lng()], text, { rotation, anchor: 'TOP_CENTER', noLeader: true }),
            update,
            connId,
            existingAnnotations
          );
        } else {
          update[connId + '~1'] = null;
          update[connId + '~2'] = null;
          update[connId + '~3'] = null;
          update[connId + '~4'] = null;
        }
        if (connFields) {
          for (let i = 0; i < connFields.length; i++) {
            let connField = connFields[i];
            if (connField.logic) {
              if (!connField.filter || KLogic.compute(connField.filter, { node: this.connections[connId], attribute: val })) {
                let annotationLabel = connField.label || 'No label';
                let logicKey = connField.logic;
                let annotationKLogicExpressions = await FirebaseWorker.ref(
                  `photoheight/company_space/${this.jobCreator}/models/map_print_annotation_logic/${logicKey}/logic`
                )
                  .once('value')
                  .then((s) => s.val());
                if (annotationKLogicExpressions) {
                  let kLogicResults = [];
                  for (let annotationKLogicExpression of annotationKLogicExpressions) {
                    // create the dataset to make available to the KLogic statements
                    let kLogicStandardDataset = GetKLogicStandardDataset('connections', jobData, this.otherAttributes, { connId });
                    // use the dataset and the expression to compute the resulting value
                    let result = KLogic.compute(annotationKLogicExpression, kLogicStandardDataset);
                    if (result != null && result != '') {
                      kLogicResults.push(result);
                    }
                  }
                  // set the resulting kLogic value to the text value
                  // split the text by new line characters so we can print each line on its own line
                  if (kLogicResults.length != 0 && i != 0) text += '\n';
                  let finalResults = kLogicResults.join(', ');
                  let resultLines = finalResults.split('\\n');
                  resultLines.forEach((result, index) => {
                    if (index != 0) text += '\n';
                    text += result;
                  });
                } else text += `\n${annotationLabel}: Couldn't find logic for this computed annotation`;
              }
            } else {
              if (connField.attribute === 'span_distances') {
                if (n1 && n2 && !SquashNulls(this.pageElement.hiddenLegendItems, geoStyle.u)) {
                  if (this.useMetricUnits) {
                    if (this.modelConfig.round_distances_up) text = Math.ceil(length) + 'm';
                    else text = Round(length, 1) + 'm';
                  } else {
                    if (this.modelConfig.round_distances_up) text = Math.ceil(length / 0.3048) + "'";
                    else text = Round(length / 0.3048, 1) + "'";
                  }
                }
                let midpoint = loadRenderMap.getMidpointLatLng(p1, p2);
                if (text != '' && midpoint) {
                  this.createMapPrintItem(
                    this.getAnnotationStyle([midpoint.lat(), midpoint.lng()], text, { rotation, anchor: 'TOP_CENTER', noLeader: true }),
                    update,
                    connId,
                    existingAnnotations
                  );
                } else {
                  update[connId + '~1'] = null;
                  update[connId + '~2'] = null;
                  update[connId + '~3'] = null;
                  update[connId + '~4'] = null;
                }
              } else {
                let values = SquashNulls(this.connections[connId].attributes, connField.attribute);
                for (let itemKey in values) {
                  let val = values[itemKey];
                  if (!connField.filter || KLogic.compute(connField.filter, { node: this.connections[connId], attribute: val })) {
                    if (val != '') {
                      if (text != '') text += '\n';
                      text += `${getLabel(connField)}${val}`;
                    }
                  }
                }
              }
            }
          }
        }
        if (text != '' && midpoint) {
          this.createMapPrintItem(
            this.getAnnotationStyle([midpoint.lat(), midpoint.lng()], text, { rotation, anchor: 'TOP_CENTER', noLeader: true }),
            update,
            connId,
            existingAnnotations
          );
        } else {
          update[connId + '~1'] = null;
          update[connId + '~2'] = null;
          update[connId + '~3'] = null;
          update[connId + '~4'] = null;
        }
      }
      await FirebaseWorker.ref(
        `photoheight/jobs/${this.jobId}/map_print_config_layers/${this.currentMapPrintConfigsKey}/annotations`
      ).update(update);
      this.annotationsLayerOn = true;
      this.toggleSelectable('annotations', true);
    }
  }
  async clearAnnotations() {
    if (await this.confirm({ title: 'Clear Annotations', body: 'Are you sure you want to clear all annotations?' })) {
      await FirebaseWorker.ref(
        `photoheight/jobs/${this.jobId}/map_print_config_layers/${this.currentMapPrintConfigsKey}/annotations`
      ).remove();
    }
  }
  snapLeaderAndEndpoints(update, key, data, options) {
    options = options || {};
    let annotation = loadRenderMap.annotationLocations?.[key];
    if (options.use_passed_data) annotation = null;
    data = data || annotation.data;
    if (annotation) {
      data.origin = annotation.data.origin; // annotation location could have changed due to drag event that we don't have record of
      data.leader = annotation.data.leader; // annotation leader location could have changed due to drag event that we don't have record of
      annotation.setPosition(data);
    }

    let topLeft = annotation?.topLeft;
    let topRight = annotation?.topRight;
    let bottomLeft = annotation?.bottomLeft;
    let bottomRight = annotation?.bottomRight;
    if (!annotation) {
      [topLeft, topRight, bottomRight, bottomLeft] = loadRenderMap.getTextOverlayCorners(data);
    }

    if (data.leader) {
      let line = [];
      if (this.katapultMaps.nodes[key] && data.leader.snapToNode) {
        let nodeLL = new google.maps.LatLng(this.katapultMaps.nodes[key].latitude, this.katapultMaps.nodes[key].longitude);
        let endPointIndex = data.leader.points.length - 1;
        let heading = google.maps.geometry.spherical.computeHeading(
          nodeLL,
          new google.maps.LatLng(...data.leader.points[endPointIndex - 1])
        );
        let leaderOffsetFromNode = (5 * parseInt(this.currentMapPrintConfig.model.feetPerInch)) / 100;
        let leaderPoint = google.maps.geometry.spherical.computeOffset(nodeLL, leaderOffsetFromNode, heading);
        update[key + '~1/leader/points/' + endPointIndex] = [leaderPoint.lat(), leaderPoint.lng()];
        update[key + '~2/leader/points/' + endPointIndex] = [leaderPoint.lat(), leaderPoint.lng()];
        update[key + '~3/leader/points/' + endPointIndex] = [leaderPoint.lat(), leaderPoint.lng()];
        update[key + '~4/leader/points/' + endPointIndex] = [leaderPoint.lat(), leaderPoint.lng()];
      }

      if (data.leader.percent == 0 && data.leader.points.length > 1) {
        let nextPoint = new google.maps.LatLng(...data.leader.points[1]);
        let closest = [
          { position: 'top', offset: 0, distance: google.maps.geometry.spherical.computeDistanceBetween(topLeft, nextPoint) },
          { position: 'left', offset: 1, distance: google.maps.geometry.spherical.computeDistanceBetween(bottomLeft, nextPoint) },
          { position: 'right', offset: 0, distance: google.maps.geometry.spherical.computeDistanceBetween(topRight, nextPoint) },
          { position: 'bottom', offset: 1, distance: google.maps.geometry.spherical.computeDistanceBetween(bottomRight, nextPoint) }
        ].sort((a, b) => a.distance - b.distance)[0];
        data.leader.edge = closest.position;
        data.leader.percent = closest.offset;
      }
      if (data.leader.edge == 'top') line = [topLeft, topRight];
      else if (data.leader.edge == 'right') line = [topRight, bottomRight];
      else if (data.leader.edge == 'bottom') line = [bottomLeft, bottomRight];
      else line = [topLeft, bottomLeft];

      let leaderEnd = KatapultGeometry.Interpolate(line[0].lat(), line[0].lng(), line[1].lat(), line[1].lng(), data.leader.percent);
      leaderEnd = [leaderEnd.lat, leaderEnd.long];
      update[key + '~1/leader/points/0'] = leaderEnd;
      update[key + '~2/leader/points/0'] = leaderEnd;
      update[key + '~3/leader/points/0'] = leaderEnd;
      update[key + '~4/leader/points/0'] = leaderEnd;

      if (annotation) {
        let path = annotation.leader.getPath();
        path.dontSaveChange = true;
        path.setAt(0, new google.maps.LatLng(leaderEnd[0], leaderEnd[1]));
      }
    }
    update[key + '~1/l'] = this.llToLoc(topLeft);
    update[key + '~2/l'] = this.llToLoc(topRight);
    update[key + '~3/l'] = this.llToLoc(bottomRight);
    update[key + '~4/l'] = this.llToLoc(bottomLeft);
  }
  llToLoc(latLng) {
    return [latLng.lat(), latLng.lng()];
  }
  manageSavedViews() {
    this.pageElement.openLayersDialog();
    this.pageElement.toggleDrawer({ currentTarget: this.pageElement.shadowRoot.querySelector('paper-item[name="savedViewsDrawer"]') });
    this.pageElement.openSavedViewManager();
  }
  manageLogos() {
    window.open(window.location.pathname.replace('/map/', '/model-editor/') + '#files/' + this.jobCreator, '_blank');
  }
  calcAnnotationBackgroundShapes(userGroup) {
    let shapes = [
      {
        title: 'Rounded Rectangle',
        icon: 'map-prints:rounded-rectangle'
      },
      {
        title: 'Circle',
        icon: 'map-prints:circle'
      },
      {
        title: 'Triangle',
        icon: 'map-prints:triangle'
      },
      {
        title: 'Hexagon',
        icon: 'map-prints:hexagon'
      },
      {
        title: 'Octagon',
        icon: 'map-prints:octagon'
      }
    ];
    if (userGroup == 'sigma_technologies') {
      shapes.push(
        {
          title: 'Dashed Triangle',
          icon: 'sigma-annotation-icons:dashed-triangle'
        },
        {
          title: 'Dashed Circle',
          icon: 'sigma-annotation-icons:dashed-circle'
        },
        {
          title: 'Dashed Square',
          icon: 'sigma-annotation-icons:dashed-square'
        }
      );
    }
    return shapes;
  }
  async openLegendEditor() {
    let legendItems = [];
    let savedView = null;
    if (this.currentMapPrintConfig?.model?.savedView) {
      savedView = await FirebaseWorker.ref(`photoheight/jobs/${this.jobId}/saved_views/${this.currentMapPrintConfig.model.savedView}`)
        .once('value')
        .then((s) => s.val());
    }
    let styles = this.currentMapPrintConfig.model.map_styles
      ? this.companyMapStyles?.[this.currentMapPrintConfig.model.map_styles]
      : this.jobStyles?.default;
    for (let key in styles) {
      if (key[0] != '_' && key[0] != '$') {
        styles[key].forEach((item) => {
          if (SquashNulls(savedView, 'settings', 'hiddenLegendItems', item.id) !== true) {
            // Get the item title and the icon for connections
            let legendItem = StyleRuleToIcon(item);

            // Get the rendered icon for points
            if (key != 'connections') {
              let iconData = GeoStyleToIcon({
                i: item.icon,
                s: item.size,
                c: item.color
              });
              let svg = iconData.url.replace('data:image/svg+xml,', '');
              // Put the # back in the SVG
              svg = svg.replace(/\%23/g, '#');
              // Convert to blob and then to image URL
              let blob = new Blob([svg], { type: 'image/svg+xml' });
              legendItem.iconSrc = URL.createObjectURL(blob);
              // Delete the un-rendered icon and style
              delete legendItem.icon;
              delete legendItem.style;
            }
            legendItem.label = this.get(`currentMapPrintConfig.model.titleBlock.legend.${item.id}.label`) ?? legendItem.title;
            legendItem.removed = this.get(`currentMapPrintConfig.model.titleBlock.legend.${item.id}.removed`);
            if (item.comparator.includes('equal')) {
              legendItem.description = `${CamelCase(item.attribute)} ${item.comparator} "${item.value}"`;
            } else {
              legendItem.description = `${CamelCase(item.comparator)} ${CamelCase(item.attribute)}`;
            }
            //Add to the list
            legendItems.push(legendItem);
          }
        });
      }
    }
    this.legendItems = legendItems;
    this.$.legendEditor.open();
  }
  toggleLegendItem(e) {
    this.set(`legendItems.${e.model.index}.removed`, !this.legendItems[e.model.index].removed);
  }
  saveLegend() {
    let legend = {};
    this.legendItems.forEach((item) => {
      legend[item.id] = { removed: item.removed || null };
      if (item.label != item.title) {
        legend[item.id].label = item.label;
      }
    });
    this.set('currentMapPrintConfig.model.titleBlock.legend', legend);
  }
  setLocalMapStyles(viewMode, mapStyles, loading) {
    let styles = viewMode == 'print' ? this.companyMapStyles?.[mapStyles] : null;
    if (this.localMapStyles != styles) {
      // Set a local version to check against to prevent loadRenderMap from being re-rendered when this function runs extra times with no change
      this.localMapStyles = styles;
      loadRenderMap.localStyle = styles ? { default: styles } : null;
    }
  }
  openKLogicEditor(e) {
    this.editingField = e.model.field;
    this.editingFieldIndex = e.model.index;
    this.$.kLogicEditorDialog.open();
  }
  saveFilter() {
    this.set(`currentMapPrintConfig.model.annotations.fields.${this.editingFieldIndex}.filter`, this.editingField.filter);
    this.notifyPath(`currentMapPrintConfig.model.annotations.fields.${this.editingFieldIndex}.filter`);
    this.editingField = null;
    this.editingFieldIndex = null;
  }
}
window.customElements.define(PrintModeToolbar.is, PrintModeToolbar);
